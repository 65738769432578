<template>
  <el-row class="tac">
    <el-col class="menu">
      <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        background-color="#FFFFFF"
        text-color="#1F2E4D"
        active-text-color="#1F2E4D"
        :router="true"
      >
        <el-menu-item index="/console/sourcetrace">
          <i class="el-icon-menu"></i>
          <span slot="title">概览</span>
        </el-menu-item>
        <el-menu-item index="/console/sourcetrace/merchant">
          <i class="el-icon-menu"></i>
          <span slot="title">商户管理</span>
        </el-menu-item>
        <el-menu-item index="/console/sourcetrace/commodity">
          <i class="el-icon-menu"></i>
          <span slot="title">商品管理</span>
        </el-menu-item>
        <el-menu-item index="/console/sourcetrace/qrcode">
          <i class="el-icon-menu"></i>
          <span slot="title">二维码管理</span>
        </el-menu-item>
        <el-menu-item index="/console/sourcetrace/batch">
          <i class="el-icon-menu"></i>
          <span slot="title">批次管理</span>
        </el-menu-item>
        <el-menu-item index="/console/sourcetrace/config">
          <i class="el-icon-menu"></i>
          <span slot="title">配置管理</span>
        </el-menu-item>
        <el-menu-item index="/console/sourcetrace/account">
          <i class="el-icon-menu"></i>
          <span slot="title">账户管理</span>
        </el-menu-item>
      </el-menu>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'SourceTraceNavMenu',
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style scoped>
.tac {
  height: 100%;
}
.menu {
  height: 100%;
}
.el-menu-vertical-demo {
  height: 100%;
}

.el-menu-item:hover {
  background-color: #2285ff !important;
  color: #ffffff !important;
}

.el-menu-item {
  text-align: left;
  font-size: 16px;
  font-family: PingFang SC Regular;
}

.el-menu {
  border-right: 0px !important;
}
</style>
