<template>
  <div class="banner">
    <div class="content">
      <div class="leftdiv">
        <div class="textdiv">
          <div class="text-div">
            <div class="title" :style="
          bannerType === 'index' ? indexBannerTextTop : normalBannerTextTop
        ">{{ title }}
            </div>
            <div class="desc"
                 :style="bannerType === 'index' ? indexBannerText : normalBannerText">
              {{ body }}
            </div>
          </div>
        </div>
      </div>
      <div style="width: 58%">
        <img :src="image" alt="" class="imagex"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: ['image', 'title', 'body', 'imgType', 'bannerType'],
  data() {
    return {
      normalBannerTextTop: {
        'font-size': '2vw',
        'font-family': 'SourceHan',
      },
      indexBannerTextTop: {
        'margin-top': 'auto',
        'font-family': 'SourceHan',
      },
      normalBannerText: {
        width: '40%',
        'font-size': '1vw',
        'line-height': '1.8vw',
        'font-family': 'SourceHan',
      },
      indexBannerText: {
        'font-family': 'SourceHan',
      },
    };
  },
};
</script>

<style scoped>

.banner {
  background: #2285ff;
  height: 36vw;
  width: 100%;
  position: relative;
  text-align: left;
  object-fit: cover;
  overflow: hidden;
}

.title {
  margin-top: -5vw;
  color: #ffffff;
  font-size: 2.9vw;
}

.desc {

  margin-top:1.2vw;
  color: #ffffff;
  font-size: 1.2vw;
  line-height: 2vw;
}

.content {
  display: flex;
  flex-direction: row;
}

.imagex {
  width: 66vw;
  height: 38vw;
}

.text-div {
  height: 28%;
  position: absolute;
}

.textdiv {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  padding: 0vw 0 0 10vw;
}

.leftdiv {
  width: 50%;
}
</style>
