<template>
  <div
    style="
      background-color: #0c1d2e;
      padding: 30px 120px 0px 120px;
      height: 420px;
      color: white;
      text-align: left;
    "
  >
    <el-row>
      <el-col :span="2">
        <h4>友情链接</h4>
      </el-col>
      <el-col :span="8" style="margin-top: 24px">
        <span
          ><el-link
            href="http://www.gtxcqfpt.com/home"
            target="_blank"
            style="color: white"
            >苏州高铁新城企业服务平台
          </el-link></span
        >
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row type="flex" justify="start" align="left" :gutter="20">
      <el-col :span="14" :offset="0">
        <el-col :span="6" :offset="0">
          <h4>基础服务</h4>
          <el-row class="footer-padding">
            <el-link
              href="/chain/basic/browser"
              target="_blank"
              style="color: white"
              >浏览器服务
            </el-link>
          </el-row>
          <el-row class="footer-padding">
            <el-link
              href="/chain/basic/crosschain"
              target="_blank"
              style="color: white"
              >跨链服务
            </el-link>
          </el-row>
          <el-row class="footer-padding">
            <el-link
              href="/chain/basic/contract"
              target="_blank"
              style="color: white"
              >智能合约和审计
            </el-link>
          </el-row>
          <el-row class="footer-padding">
            <el-link
              href="/chain/basic/aio"
              target="_blank"
              style="color: white"
              >蚂蚁一体机
            </el-link>
          </el-row>
        </el-col>
        <el-col :span="6" :offset="0">
          <h4>BaaS服务</h4>
          <el-row class="footer-padding">
            <el-link href="/chain/baas/ant" target="_blank" style="color: white"
              >蚂蚁BaaS服务
            </el-link>
          </el-row>
          <el-row class="footer-padding">
            <el-link
              href="/chain/baas/tencent"
              target="_blank"
              style="color: white"
              >腾讯TBaaS服务
            </el-link>
          </el-row>
          <el-row class="footer-padding">
            <el-link
              href="/chain/baas/huawei"
              target="_blank"
              style="color: white"
              >华为BCS服务
            </el-link>
          </el-row>
          <el-row class="footer-padding">
            <el-link
              href="/chain/baas/yiju"
              target="_blank"
              style="color: white"
              >易居E·BaaS
            </el-link>
          </el-row>
        </el-col>
        <el-col :span="6" :offset="0">
          <h4>增值服务</h4>
          <el-row class="footer-padding">
            <el-link
              href="/chain/va/evidence"
              target="_blank"
              style="color: white"
              >存证服务
            </el-link>
          </el-row>
          <el-row class="footer-padding">
            <el-link
              href="/chain/va/privacy"
              target="_blank"
              style="color: white"
              >隐私计算
            </el-link>
          </el-row>
          <el-row class="footer-padding">
            <el-link
              href="/chain/va/oracle"
              target="_blank"
              style="color: white"
              >预言机服务
            </el-link>
          </el-row>
          <el-row class="footer-padding">
            <el-link href="/chain/va/did" target="_blank" style="color: white"
              >数字身份
            </el-link>
          </el-row>
          <el-row class="footer-padding">
            <el-link href="/chain/va/asset" target="_blank" style="color: white"
              >数字资产
            </el-link>
          </el-row>
          <el-row class="footer-padding">
            <el-link
              href="/chain/va/signature"
              target="_blank"
              style="color: white"
              >数字签章
            </el-link>
          </el-row>
          <el-row class="footer-padding">
            <el-link
              href="/chain/va/escrow"
              target="_blank"
              style="color: white"
              >私钥托管
            </el-link>
          </el-row>
        </el-col>
        <el-col :span="6" :offset="0">
          <h4>行业链服</h4>
          <el-row class="footer-padding">
            <el-link
              href="/chain/alliance/antsc"
              target="_blank"
              style="color: white"
              >蚂蚁溯源链服
            </el-link>
          </el-row>
          <el-row class="footer-padding">
            <el-link
              href="/chain/alliance/justice"
              target="_blank"
              style="color: white"
              >司法链服
            </el-link>
          </el-row>
          <el-row class="footer-padding">
            <el-link
              href="/chain/alliance/ssebs"
              target="_blank"
              style="color: white"
              >上证链服
            </el-link>
          </el-row>
          <el-row class="footer-padding">
            <el-link
              href="/chain/alliance/iec"
              target="_blank"
              style="color: white"
              >保交链服
            </el-link>
          </el-row>
          <el-row class="footer-padding">
            <el-link
              href="/chain/alliance/wtc"
              target="_blank"
              style="color: white"
              >梧桐链服
            </el-link>
          </el-row>
        </el-col>
      </el-col>
      <el-col :span="7">
        <h4>联系方式</h4>
        <el-row class="footer-padding">
          <font-awesome-icon icon="envelope" class="footer-icon-width" />
          <el-link
            href="mailto:op@yuhu.tech"
            style="color: white; font-size: 16px"
            >op@yuhu.tech
          </el-link>
          <!-- <el-divider direction="vertical"></el-divider>
          <el-link
            href="mailto:public@yuhu.tech"
            style="color: white; font-size: 16px"
            >public@yuhu.tech
          </el-link> -->
        </el-row>
        <el-row class="footer-padding">
          <font-awesome-icon icon="map-marker" class="footer-icon-width" />
          <span class="footer-text">上海市杨浦区国权北路1688弄A8栋5层</span>
        </el-row>
        <el-row class="footer-padding">
          <font-awesome-icon icon="copyright" class="footer-icon-width" />
          <span class="footer-text">上海域乎信息技术有限公司</span>
        </el-row>
         <el-row class="footer-padding">
          <font-awesome-icon icon="mobile-alt" class="footer-icon-width" />
          <span class="footer-text">业务咨询：15205848166</span>
        </el-row>
        <el-row class="footer-padding">
          <font-awesome-icon icon="headphones-alt" class="footer-icon-width" />
          <span class="footer-text">市场合作：13512151979</span>
        </el-row>
        <el-row style="padding-top: 10px">
          <el-button
            type="primary"
            size="small"
            style="
              font-size: large;
              font-family: 'Jetbrains Mono', Consolas, Monaco, monospace;
            "
            ><b>(021) 6142-3561 </b></el-button
          >
        </el-row>
      </el-col>
      <el-col :span="3" style="display: flex; justify-content: flex-end">
        <div>
          <h4>公众号</h4>
          <img src="@/assets/image/qr/Wechat.jpg" style="width: 80%" />
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top: 20px">
      <span class="isp-note">
        <!-- <span class="isp-note-text">沪ICP备18018923号-2</span> -->
        <span
          ><el-link
            :underline="false"
            href="https://beian.miit.gov.cn/"
            target="_blank"
            class="isp-note-text"
            >沪ICP备18018923号-2</el-link
          >
        </span>
        <img
          :src="require('@/assets/image/footer/badge.png')"
          style="padding-right: 10px; padding-left: 10px; opacity: 1"
          alt="badge"
        />
        <span
          ><el-link
            :underline="false"
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011002005243"
            class="isp-note-text"
          >
            沪公安网备31011002005243号
          </el-link>
        </span>
      </span>
    </el-row>
  </div>
</template>

<script>
// eslint-disable-next-line no-use-before-define,no-underscore-dangle,prefer-const
let _hmt = _hmt || [];
function baidu() {
  // eslint-disable-next-line no-debugger
  // eslint-disable-next-line prefer-const
  let hm = document.createElement('script');
  hm.src = 'https://hm.baidu.com/hm.js?f034173f6818358950bf9205ba88308f';
  // eslint-disable-next-line prefer-const
  let s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(hm, s);
}
baidu();
export default {
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@700&display=swap');

.divider {
  opacity: 0.4;
}

.footer-padding {
  padding-bottom: 7px;
}

.el-divider--horizontal {
  margin: 5px 0;
}

.el-divider {
  opacity: 0.3;
}

.isp-note {
  display: flex;
  justify-content: center;
  font-size: small;
}

.isp-note-text {
  opacity: 0.4;
  color: white;
  font-size: 13px;
}

.footer-text {
  font-size: 14px;
  font-weight: bold;
}

.footer-icon-width {
  width: 13px;
  padding-right: 7px;
}
</style>
