<template>
  <div>
    <div class="box-top">
      <el-button @click="add">添加规则</el-button>

      <el-dialog
        :visible.sync="dialogVisible"
        width="40%"
        title="添加二维码规则"
      >
        <div>
          <div class="dialog-input">
            <div class="dialog-text">*商户名称</div>
            <el-select
              v-model="addForm.selectMerchant"
              placeholder="商户名称"
              class="input"
            >
              <el-option
                v-for="item in merchants"
                :key="item.id"
                :label="item.merchantName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="dialog-input">
            <div class="dialog-text">*商品名称</div>
            <el-select
              v-model="addForm.selectCommodity"
              placeholder="商品名称"
              class="input"
            >
              <el-option
                v-for="item in commoditis"
                :key="item.id"
                :label="item.commodityName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="dialog-input">
            <div class="dialog-text">规则编号</div>
            <el-input
              v-model="addForm.number"
              placeholder="规则编号"
              class="input"
            ></el-input>
          </div>
          <div class="dialog-input">
            <div class="dialog-text">规则</div>
            <el-input
              v-model="addForm.rule"
              placeholder="规则"
              class="input"
            ></el-input>
          </div>
          <div class="dialog-input">
            <div class="dialog-text">示例</div>
            <el-input
              v-model="addForm.example"
              placeholder="示例"
              class="input"
            ></el-input>
          </div>
          <div class="dialog-input">
            <div class="dialog-text">*标示类型</div>
            <el-select
              v-model="addForm.selectType"
              placeholder="标示类型"
              class="input"
            >
              <el-option
                v-for="item in types"
                :key="item.id"
                :label="item.typeName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="dialog-input">
            <div class="dialog-text">*备注</div>
            <el-input
              type="textarea"
              v-model="addForm.desc"
              placeholder="备注"
              class="input"
            ></el-input>
          </div>
          <span slot="footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">
              确 定
            </el-button>
          </span>
        </div>
      </el-dialog>
    </div>

    <el-dialog
      :visible.sync="dialogVisibleGenQrcode"
      width="40%"
      title="生成二维码"
    >
      <div>
        <div class="dialog-input">
          <div class="dialog-text">生成二维码数量</div>
          <el-input
            v-model="genQrcodeForm.count"
            placeholder="生成二维码数量"
            class="input"
          ></el-input>
        </div>
        <div class="dialog-input">
          <div class="dialog-text">选择绑定批次</div>
          <el-select
            v-model="genQrcodeForm.selectSn"
            placeholder=""
            class="input"
          >
            <el-option
              v-for="item in sns"
              :key="item.sn"
              :label="item.sn"
              :value="item.sn"
            >
            </el-option>
          </el-select>
        </div>
        <span slot="footer">
          <el-button @click="dialogVisibleGenQrcode = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisibleGenQrcode = false">
            确 定
          </el-button>
        </span>
      </div>
    </el-dialog>
    <div>
      <el-table :data="tableData" border>
        <el-table-column prop="number" label="编号" width="180">
        </el-table-column>
        <el-table-column prop="rule" label="规则"> </el-table-column>
        <el-table-column prop="desc" label="描述" width="180">
        </el-table-column>
        <el-table-column prop="merchant" label="商户"> </el-table-column>
        <el-table-column prop="commodity" label="商品"> </el-table-column>
        <el-table-column prop="operation" label="操作" width="180">
          <template v-slot="scope">
            <el-button @click="genQrcode(scope.row)" type="text" size="small">
              生成二维码
            </el-button>
            <el-button @click="dialogVisible = true" type="text" size="small">
              编辑
            </el-button>
            <el-button type="text" size="small"> 删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          number: '12',
          rule: 'rule',
          desc: '',
          merchant: '浙江绿迹农业科技有限公司',
          commodity: '口感型大番茄',
          operation: '',
        },
      ],
      dialogVisibleGenQrcode: false,
      dialogVisible: false,
      merchants: [
        {
          id: 'id1',
          merchantName: '浙江绿迹农业科技有限公司',
        },
        {
          id: 'id2',
          merchantName: '上海歆香蔬果专业合作社',
        },
      ],
      commoditis: [
        {
          id: 'id1',
          commodityName: '口感型大番茄',
        },
        {
          id: 'id2',
          commodityName: '生菜',
        },
      ],
      types: [
        {
          id: 'id1',
          typeName: '普通码',
        },
        {
          id: 'id2',
          typeName: '明暗码',
        },
      ],
      addForm: {
        number: '',
        rule: '',
        example: '',
        selectMerchant: '',
        selectCommodity: '',
        selectType: '',
        desc: '',
      },
      sns: [
        {
          sn: 'sn1',
        },
        {
          sn: 'sn2',
        },
      ],
      genQrcodeForm: {
        count: '',
        selectSn: '',
      },
    };
  },
  methods: {
    genQrcode(row) {
      console.log(row);
      this.dialogVisibleGenQrcode = true;
    },
    add() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped>
.box-config {
  width: 100%;
}

.dialog-input {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-around;
  text-align: left;
}

.dialog-text {
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input {
  width: 100%;
}
</style>
