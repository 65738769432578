<template>
  <el-container class="box">
    <div class="box-displayboard">
      <el-col :span="24">
        <el-menu
          default-active="1"
          mode="horizontal"
          @select="handleSelect"
          class="menu"
        >
          <el-menu-item index="1"> 微信认证 </el-menu-item>
          <el-menu-item index="2"> 支付宝认证 </el-menu-item>
          <el-menu-item index="3"> 银行卡认证 </el-menu-item>
          <el-menu-item index="4"> CTID认证 </el-menu-item>
          <el-menu-item index="5"> 活体认证 </el-menu-item>
        </el-menu>
      </el-col>
      <div class="box-body">
        <el-table :data="configTableData" border>
          <el-table-column prop="key" label="基本信息">
            <template v-slot="scope">
              <div style="display: flex">
                <div style="width: 200px">{{ scope.row.key }}</div>
                <div>{{ scope.row.value }}</div>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="value" label="">
          </el-table-column> -->
          <el-table-column prop="configTableData" label="操作" width="380">
            <template v-slot="scope">
              <el-button
                v-if="scope.row.key === 'AccessKeySecret'"
                @click="fix(scope.row)"
                type="text"
                size="small"
              >
                重置
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-card shadow="hover" class="box-card">
          <div style="margin-bottom: 10px">配置服务器信息</div>
          <el-button @click="config1()"> 配置 </el-button>
        </el-card>
        <el-card shadow="hover" class="box-card">
          <div style="margin-bottom: 10px">消息推送配置</div>
          <el-button @click="config2()"> 配置 </el-button>
        </el-card>

        <el-dialog
          :visible.sync="dialogVisible1"
          width="45%"
          title="配置服务器信息"
        >
          <div>
            <div class="dialog-input">
              <div class="dialog-text">request合法域名</div>
              <el-input
                v-model="config1Form.request"
                placeholder="以https://开头，可填写多个域名"
                class="input"
              ></el-input>
            </div>
            <div class="dialog-input">
              <div class="dialog-text">socket合法域名</div>
              <el-input
                v-model="config1Form.socket"
                placeholder="wss://"
                class="input"
              ></el-input>
            </div>
            <div class="dialog-input">
              <div class="dialog-text">uploadFile合法域名</div>
              <el-input
                v-model="config1Form.uploadFile"
                placeholder="以https://开头，可填写多个域名"
                class="input"
              ></el-input>
            </div>
            <div class="dialog-input">
              <div class="dialog-text">downloadFile合法域名</div>
              <el-input
                v-model="config1Form.downloadFile"
                placeholder="以https://开头，可填写多个域名"
                class="input"
              ></el-input>
            </div>
            <div class="dialog-input">
              <div class="dialog-text">udp合法域名</div>
              <el-input
                v-model="config1Form.udp"
                placeholder="udp://"
                class="input"
              ></el-input>
            </div>
            <span slot="footer">
              <el-button @click="dialogVisible1 = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible1 = false">
                保存
              </el-button>
            </span>
          </div>
        </el-dialog>

        <el-dialog
          :visible.sync="dialogVisible2"
          width="55%"
          title="消息推送配置"
        >
          <div>
            <div class="dialog-input">
              <div class="dialog-text">URL(服务器地址)</div>
              <el-input
                v-model="config2Form.url"
                placeholder="以https://开头，分别支持80端口和443端口"
                class="input"
              ></el-input>
            </div>
            <div class="dialog-input">
              <div class="dialog-text">token</div>
              <el-input
                v-model="config2Form.token"
                placeholder=""
                class="input"
              ></el-input>
            </div>
            <div class="dialog-input">
              <div class="dialog-text">EncodingAESkey</div>
              <div
                class="input"
                style="display: flex; justify-content: space-between"
              >
                <el-input
                  v-model="config2Form.AESkey"
                  placeholder=""
                  class="input2"
                ></el-input>
                <el-button>随机生成</el-button>
              </div>
            </div>
            <div class="dialog-input">
              <div class="dialog-text">数据格式</div>
              <div class="dialog-radio">
                <el-radio v-model="config2Form.dataType" label="JSON">
                  JSON
                </el-radio>
                <el-radio v-model="config2Form.dataType" label="XML">
                  XML
                </el-radio>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible2 = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible2 = false">
                保 存
              </el-button>
            </span>
          </div>
        </el-dialog>
      </div>
    </div>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      configTableData: [
        {
          type: 'wechat',
          key: 'AccessKeyId',
          value: 'D7C37d325CD4f925190c04',
        },
        {
          type: 'wechat',
          key: 'AccessKeySecret',
          value: '2EE4f4B68be817515B7f64D74B625FA99a79a',
        },
      ],
      dialogVisible1: false,
      dialogVisible2: false,
      config1Form: {
        request: '',
        socket: '',
        uploadFile: '',
        downloadFile: '',
        udp: '',
      },
      config2Form: {
        url: '',
        token: '',
        AESkey: '',
        dataType: 'JSON',
      },
    };
  },
  methods: {
    handleSelect(key) {
      this.active = key;
    },
    config1() {
      this.dialogVisible1 = true;
    },
    config2() {
      this.dialogVisible2 = true;
    },
    fix(row) {
      console.log(row);
    },
  },
};
</script>

<style scoped>
.box {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
}

.box-displayboard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.box-body {
  height: 100%;
  background-color: #ffffff;
}

.box-card {
  margin-top: 20px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dialog-input {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  text-align: left;
}
.dialog-text {
  width: 30%;
  display: flex;
  align-items: center;
}
.input {
  width: 70%;
}
.input2 {
  margin-right: 20px;
}
</style>
