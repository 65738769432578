<template>
  <el-container class="box">
    <div class="box-top">
      <span>
        <Button @click="addNode" text="添加节点" />
      </span>
      <Dialog
        :visible="dialogVisible"
        :title="'添加节点'"
        @dialogClose="dialogVisible = false"
      >
        <!-- <el-dialog :visible.sync="dialogVisible" width="40%" title="添加节点"> -->
        <!-- <div> -->
        <!-- <div class="dialog-input">
            <div class="dialog-text">*联系人</div>
            <el-input
              v-model="addForm.contacts"
              placeholder="联系人"
              class="input"
            ></el-input>
          </div>
          <div class="dialog-input">
            <div class="dialog-text">*联系电话</div>
            <el-input
              v-model="addForm.contactPhone"
              placeholder="联系电话"
              class="input"
            ></el-input>
          </div>
          <div class="dialog-input">
            <div class="dialog-text">*联系邮箱</div>
            <el-input
              v-model="addForm.contactEmail"
              placeholder="联系邮箱"
              class="input"
            ></el-input>
          </div>
          <div class="dialog-input">
            <div class="dialog-text">*申请原因</div>
            <el-input
              type="textarea"
              v-model="addForm.reason"
              placeholder="申请原因"
              class="input"
            ></el-input>
          </div> -->
        <el-form
          ref="form"
          :model="addForm"
          label-width="auto"
          label-position="left"
        >
          <el-form-item label="*联系人">
            <el-input v-model="addForm.reason" placeholder="联系人"></el-input>
          </el-form-item>
          <el-form-item label="*联系电话">
            <el-input
              v-model="addForm.reason"
              placeholder="联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="*联系邮箱">
            <el-input
              v-model="addForm.contactEmail"
              placeholder="联系邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item label="*申请原因">
            <el-input
              v-model="addForm.reason"
              type="textarea"
              placeholder="申请原因"
            ></el-input>
          </el-form-item>
        </el-form>
        <span style="display: flex; justify-content: center">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">
            提交申请
          </el-button>
        </span>
        <!-- </div> -->
        <!-- </el-dialog> -->
      </Dialog>
    </div>
    <el-container>
      <el-row>
        <el-col
          class="card-col"
          v-for="(item, index) in tableData"
          :key="index"
          :style="index % 3 === 0 ? '' : 'margin-left: 20px'"
        >
          <el-card shadow="hover" class="box-card">
            <strong>{{ item.name }}_{{ item.address }}</strong>
            <div class="card-top">
              <div class="card-top-item1">
                <div class="card-top-item1-diskrate">
                  <p>磁盘占比：</p>
                  <p>{{ item.disk.rate }}</p>
                </div>
                <!-- <img
                  class="card-pic"
                  src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
                /> -->
                <NodeEchart class="card-pic" />
              </div>
              <div style="width: 100%">
                <a> 磁盘总量：{{ item.disk.amount }} </a>
                <a> 已使用量：{{ item.disk.usage }} </a>
              </div>
            </div>
            <div class="card-bottom">
              <p>节点所有者：{{ item.nodeOwner }}</p>
              <p>节点ID：{{ item.nodeId }}</p>
              <p>IP访问端口：{{ item.address }}:{{ item.port }}</p>
              <p>公钥：{{ item.pubKey }}</p>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-container>
  </el-container>
</template>

<script>
import { Button, Dialog } from '@/components/common/Index';
import NodeEchart from './NodeEchart.vue';

export default {
  components: {
    Button,
    Dialog,
    NodeEchart,
  },
  data() {
    return {
      tableData: [
        {
          name: '云节点',
          address: '127.0.0.1',
          port: '22',
          nodeOwner: 'nodeOwner',
          nodeId: '0xFFFFFFF',
          pubKey: '0xFFFAAAAA...........ADG',
          disk: {
            rate: '0.11%',
            amount: '500G',
            usage: '2.1G',
          },
        },
        {
          name: '云节点',
          address: '127.0.0.1',
          port: '22',
          nodeOwner: 'nodeOwner',
          nodeId: '0xFFFFFFF',
          pubKey: '0xFFFAAAAA...........ADG',
          disk: {
            rate: '0.11%',
            amount: '500G',
            usage: '2.1G',
          },
        },
        {
          name: '云节点',
          address: '127.0.0.1',
          port: '22',
          nodeOwner: 'nodeOwner',
          nodeId: '0xFFFFFFF',
          pubKey: '0xFFFAAAAA...........ADG',
          disk: {
            rate: '0.11%',
            amount: '500G',
            usage: '2.1G',
          },
        },
        {
          name: '云节点',
          address: '127.0.0.1',
          port: '22',
          nodeOwner: 'nodeOwner',
          nodeId: '0xFFFFFFF',
          pubKey: '0xFFFAAAAA...........ADG',
          disk: {
            rate: '0.11%',
            amount: '500G',
            usage: '2.1G',
          },
        },
      ],
      dialogVisible: false,
      addForm: {
        contacts: '',
        contactPhone: '',
        contactEmail: '',
        reason: '',
      },
    };
  },
  methods: {
    addNode() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped>
.box {
  display: flex;
  flex-direction: column;
}
.top-text {
  margin-top: 6px;
  margin-left: 30px;
}
.box2 {
  display: flex;
  flex-wrap: wrap;
}
.button-top {
  margin-left: 56px;
}
.card-col {
  width: calc((100% - 40px) / 3);
}
.box-card {
  height: 380px;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 20px;
}
.card-top {
  border-bottom: 1px solid #e6e6e6;
  height: 150px;
  margin-top: 10px;
}
.card-pic {
  width: 120px;
  height: 120px;
  margin-left: 10px;
}
.card-top-item1 {
  width: 100%;
  display: flex;
}
.card-top-item1-diskrate {
  width: 50%;
  text-align: center;
}
.dialog-input {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-around;
  text-align: left;
}
.dialog-text {
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
