<template>
  <div class="node-body">
    <div>
      <el-table :data="tableData" border>
        <el-table-column prop="name" label="应用名称" min-width="25%">
        </el-table-column>
        <el-table-column prop="desc" label="描述" min-width="50%">
        </el-table-column>
        <el-table-column prop="operation" label="操作" min-width="25%">
          <template v-slot="scope">
            <Button
              @click="submit(scope.row)"
              type="mini-blue"
              size="small"
              text="详情"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="bottom">
      <Pagination />
    </div>

    <Dialog
      :visible="dialogVisible"
      :size="'medium'"
      :title="'合约浏览器'"
      @dialogClose="dialogVisible = false"
    >
      <el-form
        ref="form"
        :model="formAddApp"
        label-width="0"
        label-position="left"
      >
        <!-- <el-form-item label=""> -->
        <div style="display: flex; justify-content: flex-end">
          <el-select
            v-model="formAddApp.blockchain"
            placeholder="合约链"
            style="margin-right: 20px"
          >
            <el-option label="合约链" value="1"></el-option>
          </el-select>
          <el-select
            v-model="formAddApp.tech"
            placeholder="SpringBoot(Maven)"
            style="margin-right: 20px"
          >
            <el-option label="SpringBoot(Maven)" value="1"></el-option>
          </el-select>
          <Button type="white" @click="dialogVisible = false" text="提交" />
        </div>
        <!-- </el-form-item> -->
      </el-form>
      <img
        src="https://picsum.photos/600/300"
        width="100%"
        style="margin-top: 20px"
      />
    </Dialog>
  </div>
</template>

<script>
import { Pagination, Button, Dialog } from '@/components/common/Index';

export default {
  components: {
    Pagination,
    Button,
    Dialog,
  },
  data() {
    return {
      active: 1,
      activeIndex: '1',
      tableData: [
        {
          name: '账户白名单',
          desc: '账户白名单服务',
          operation: '',
        },
      ],
      dialogVisible: false,
      formAddApp: {
        type: '',
        blockchain: '',
        name: '',
        tech: '',
        GroupID: '',
        ArtifactID: '',
        reminder: '',
      },
    };
  },
  methods: {
    handleSelect(key) {
      this.active = key;
    },
    submit(row) {
      this.dialogVisible = true;
      console.log(row);
    },
  },
};
</script>

<style scoped>
.node-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bottom {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
</style>
