<template>
  <div style="display: inline-flex; align-items: center">
    <el-button
      v-if="type === 'svg' || type === 'png'"
      type="text"
      @click="handleClick"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div style="display: flex; align-items: center; margin-right: 5px">
          <inline-svg
            v-if="type === 'svg'"
            :src="src"
            class="svg"
            :fill="fill"
          />
          <img v-else-if="type === 'png'" :src="src" />
        </div>
        <span v-if="text">{{ text }}</span>
        <slot v-else />
      </div>
    </el-button>
    <!-- <el-button
      v-else-if="
        type === 'mini-red' ||
        type === 'mini-green' ||
        type === 'mini-yellow' ||
        type === 'mini-blue'
      "
      :type="type"
      @click="handleClick"
    >
      <span v-if="text">{{ text }}</span>
      <slot v-else />
    </el-button> -->
    <el-button v-else :type="type" @click="handleClick">
      <span v-if="text">{{ text }}</span>
      <slot v-else />
    </el-button>
  </div>
</template>

<script>
export default {
  props: ['src', 'fill', 'text', 'type'],
  data() {
    return {};
  },
  methods: {
    handleClick() {
      this.$emit('click', {});
    },
  },
};
</script>

<style scoped>
.svg {
  height: 20px;
  width: 20px;
}

.el-button {
  background: #ffffff;
  border-radius: 4px;
  border-color: transparent;
  font-size: 16px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #3d4966;
  padding: 0;
  line-height: normal;
}

.el-button--white {
  width: 120px;
  height: 38px;
  background: #2285ff;
  border-radius: 4px;
  font-size: 16px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}

.el-button--blue {
  width: 120px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #2285ff;
  border-radius: 4px;
  font-size: 16px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: 500;
  text-align: center;
  color: #2285ff;
}

.el-button--mini-red {
  background: 0 0;
  border: 0px;
  font-size: 14px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #ff5017;
}

.el-button--mini-blue {
  background: 0 0;
  border: 0px;
  font-size: 14px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #2285ff;
}

.el-button--mini-yellow {
  background: 0 0;
  border: 0px;
  font-size: 14px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #fecb18;
}

.el-button--mini-green {
  background: 0 0;
  border: 0px;
  font-size: 14px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #5ec95a;
}
</style>
