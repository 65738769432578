<template>
  <iframe
    v-bind:src="url"
    scrolling="no"
    border="0"
    frameborder="no"
    framespacing="0"
    allowfullscreen="false"
  >
  </iframe>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: ['url'],
});
</script>
