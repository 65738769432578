<template>
  <div>
    <div class="box-title">
      <el-input
        v-model="searchDataAndName"
        placeholder="按数据以及名称搜索"
        style="width: 300px"
        suffix-icon="el-icon-search"
      ></el-input>
      <el-button> 添加数据 </el-button>
    </div>
    <div class="box-body">
      <div class="box-body-top">
        <el-input
          v-model="searchName"
          placeholder="名称搜索"
          style="width: 300px"
          suffix-icon="el-icon-search"
        ></el-input>
        <el-button> 创建实验数据集 </el-button>
      </div>

      <div>
        <el-table :data="tableData" border class="box-body-table">
          <el-table-column prop="name" label="实验数据集名称" min-width="7">
          </el-table-column>
          <el-table-column prop="sourceData" label="来源数据" min-width="4">
          </el-table-column>
          <el-table-column prop="recordNum" label="记录数" min-width="3">
          </el-table-column>
          <el-table-column prop="fieldNum" label="字段数" min-width="3">
          </el-table-column>
          <el-table-column prop="modelNum" label="模型数" min-width="3">
          </el-table-column>
          <el-table-column prop="date" label="更新日期" min-width="4">
          </el-table-column>
          <el-table-column prop="status" label="状态" min-width="4">
          </el-table-column>
          <el-table-column prop="configTableData" label="操作" width="180">
            <template v-slot="scope">
              <el-dropdown>
                <span class="el-dropdown-link">
                  数据处理<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="dialogVisible1 = true">
                    过滤
                  </el-dropdown-item>
                  <el-dropdown-item @click.native="dialogVisible2 = true">
                    分箱
                  </el-dropdown-item>
                  <el-dropdown-item @click.native="dialogVisible3 = true">
                    配置文件
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button @click="doSomething(scope.row)" type="text">
                更多
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-dialog
        :visible.sync="dialogVisible1"
        width="45%"
        title="设置过滤条件"
      >
        <el-row>
          <el-col span="7" offset="0">
            <div>过滤字段</div>
            <el-select v-model="dialog1Form.selectField" placeholder="过滤字段">
              <el-option label="字段1" value="field1"></el-option>
              <el-option label="字段2" value="field2"></el-option>
            </el-select>
          </el-col>
          <el-col span="7" offset="1">
            <div>过滤器</div>
            <el-select v-model="dialog1Form.selectSymbol" placeholder="过滤器">
              <el-option label=">" value=">"></el-option>
              <el-option label=">=" value=">="></el-option>
              <el-option label="<" value="<"></el-option>
              <el-option label="<=" value="<="></el-option>
              <el-option label="=" value="="></el-option>
              <el-option label="≠" value="≠"></el-option>
            </el-select>
          </el-col>
          <el-col span="8" offset="1">
            <div>过滤器</div>
            <el-input
              v-model="dialog1Form.inputData"
              placeholder="请输入过滤值"
            ></el-input>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-button style="width: 100%" icon="el-icon-plus">
            添加过滤条件
          </el-button>
        </el-row>
      </el-dialog>

      <el-dialog :visible.sync="dialogVisible2" width="80%" title="数据集1">
        <Set1 />
      </el-dialog>

      <el-dialog :visible.sync="dialogVisible3" width="80%" title="数据集2">
        <Set2 />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Set1 from './Set1.vue';
import Set2 from './Set2.vue';

export default {
  data() {
    return {
      searchDataAndName: '',
      searchName: '',
      tableData: [
        {
          name: 'sora',
          sourceData: 'sourceData',
          recordNum: 1,
          fieldNum: 1,
          modelNum: 1,
          date: '2021-01-01',
          status: '关联成功',
        },
      ],
      dialogVisible1: false,
      dialog1Form: {
        selectField: '',
        selectSymbol: '',
        inputData: '',
      },
      dialogVisible2: false,
      dialogVisible3: false,
    };
  },
  components: {
    Set1,
    Set2,
  },
};
</script>

<style scoped>
.box-title {
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-family: PingFang SC Regular;
}

.box-body-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
</style>
