<template>
  <el-row class="tac">
    <el-col class="menu">
      <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        background-color="#FFFFFF"
        text-color="#1F2E4D"
        active-text-color="#1F2E4D"
        :router="true"
      >
        <el-menu-item index="/console/did">
          <i class="el-icon-menu"></i>
          <span slot="title">概览</span>
        </el-menu-item>
        <el-menu-item index="/console/did/account">
          <i class="el-icon-menu"></i>
          <span slot="title">用户管理</span>
        </el-menu-item>
        <el-menu-item index="/console/did/scan">
          <i class="el-icon-menu"></i>
          <span slot="title">浏览器</span>
        </el-menu-item>
        <el-menu-item index="/console/did/config">
          <i class="el-icon-menu"></i>
          <span slot="title">配置</span>
        </el-menu-item>
      </el-menu>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'DIDNavMenu',
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style scoped>
.tac {
  height: 100%;
}

.menu {
  height: 100%;
}

.el-menu-vertical-demo {
  height: 100%;
}

.el-menu-item:hover {
  background-color: #2285ff !important;
  color: #ffffff !important;
}

.el-menu-item {
  text-align: left;
  font-size: 16px;
  font-family: PingFang SC Regular;
}

.el-menu {
  border-right: 0px !important;
}
</style>
