<template>
  <div class="box-config">
    <el-table :data="configList" class="box-table" border>
      <el-table-column prop="name" label="配置名称" width="180">
      </el-table-column>
      <el-table-column prop="desc" label="描述" width="500"> </el-table-column>
      <el-table-column prop="data" label="值"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      configList: [
        {
          name: 'name123',
          desc: 'descfdasjogpasfodsg',
          data: 'data值',
        },
      ],
    };
  },
};
</script>

<style scoped>
.box-config {
  margin-top: 20px;
  width: 100%;
}
</style>
