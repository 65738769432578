<template>
  <div>
    <div class="box-top">
      <el-button @click="add">新建模版</el-button>

      <el-dialog :visible.sync="dialogVisible" width="40%" title="新建溯源模版">
        <div>
          <div class="dialog-input">
            <div class="dialog-text">*模版名称</div>
            <el-input
              v-model="addForm.name"
              placeholder="请输入模版名称"
              class="input"
            ></el-input>
          </div>
          <div class="dialog-input">
            <div class="dialog-text">*溯源环节名称</div>
            <el-input
              v-model="addForm.traceabilityLink"
              placeholder="请输入溯源环节名称"
              class="input"
            ></el-input>
          </div>
          <div class="dialog-input">
            <div class="dialog-text">*溯源环节编号</div>
            <el-input
              v-model="addForm.traceabilityLinkSn"
              placeholder="溯源环节编号"
              class="input"
            ></el-input>
          </div>
          <div class="dialog-input">
            <div class="dialog-text">备注</div>
            <el-input
              type="textarea"
              v-model="addForm.desc"
              placeholder="备注"
              class="input"
            ></el-input>
          </div>
          <div class="button-icon-plus">
            <el-button
              plain
              icon="el-icon-plus"
              style="width: 100%"
              @click="dialogVisible = false"
            ></el-button>
          </div>
          <span slot="footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">
              确 定
            </el-button>
          </span>
        </div>
      </el-dialog>
    </div>
    <div>
      <el-table :data="tableData" border>
        <el-table-column prop="name" label="模版名称" width="180">
        </el-table-column>
        <el-table-column prop="createDate" label="创建时间"> </el-table-column>
        <el-table-column prop="creator" label="创建人" width="180">
        </el-table-column>
        <el-table-column prop="traceabilityLinkCount" label="环节数量">
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="180">
          <template v-slot="scope">
            <el-button @click="submit(scope.row)" type="text" size="small">
              详情
            </el-button>
            <el-button @click="dialogVisible = true" type="text" size="small">
              编辑
            </el-button>
            <el-button type="text" size="small"> 删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          name: '大番茄专用模版',
          createDate: '2020-09-22',
          creator: '张三',
          traceabilityLinkCount: 3,
          operation: '',
        },
      ],
      dialogVisible: false,
      merchants: [
        {
          id: 'id1',
          merchantName: '浙江绿迹农业科技有限公司',
        },
        {
          id: 'id2',
          merchantName: '上海歆香蔬果专业合作社',
        },
      ],
      commoditis: [
        {
          id: 'id1',
          commodityName: '口感型大番茄',
        },
        {
          id: 'id2',
          commodityName: '生菜',
        },
      ],
      addForm: {
        name: '',
        traceabilityLink: '',
        traceabilityLinkSn: '',
        desc: '',
      },
    };
  },
  methods: {
    add() {
      this.dialogVisible = true;
    },
    submit(row) {
      console.log(row);
    },
  },
};
</script>

<style scoped>
.box-config {
  width: 100%;
}

.dialog-input {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-around;
  text-align: left;
}

.dialog-text {
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input {
  width: 100%;
}

.button-icon-plus {
  margin: 20px 0 20px 0;
  display: flex;
  justify-content: center;
}
</style>
