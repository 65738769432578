<template>
  <el-dialog
    :title="title"
    :visible.sync="openStatus"
    custom-class="dialog-form-style"
    :width="width"
    :show="openStatus"
    @open="doOpen"
    @close="doClose"
  >
    <!-- <el-divider></el-divider> -->
    <slot></slot>
  </el-dialog>
</template>
<script>
export default {
  name: 'Dialog',

  props: {
    title: {
      type: String,
      default: 'No Title',
    },
    size: {
      type: String,
      default: 'mini',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      width: '400px',
      openStatus: this.visible,
    };
  },
  watch: {
    visible(val) {
      this.openStatus = val;
      switch (this.size) {
        case 'auto':
          this.width = '';
          break;
        case 'medium':
          this.width = '800px';
          break;
        case 'small':
          this.width = '600px';
          break;
        case 'mini':
          this.width = '400px';
          break;
        default:
          this.width = '600px';
          break;
      }
      console.log('watch visible', this.visible, this.size, this.width);
    },
  },

  methods: {
    doOpen() {},
    doClose() {
      this.$emit('dialogClose', false);
    },
  },
};
</script>

<style lang='scss'>
.dialog-form-style {
  text-align: left;
  .el-dialog__header {
    display: flex;
    justify-content: flex-start;
    .el-dialog__title {
      font-size: 16px;
      font-family: PingFang SC Regular, PingFang SC Regular-Regular;
      font-weight: 400;
      text-align: left;
      color: #1f2e4d;
      line-height: 16px;
      text-shadow: 0px 0px 20px 0px rgba(31, 45, 75, 0.1);
    }
  }
  .el-dialog__body {
    padding-top: 10px;
    .el-divider--horizontal {
      margin-top: 0;
      margin-bottom: 20px;
    }
    .el-form {
      margin-bottom: 20px;
    }
    .el-form-item {
      margin-bottom: 0;
      .el-form-item__label-wrap {
        margin-left: 0 !important;
      }
      .el-form-item__content {
        margin-left: 0 !important;
        font-size: 14px;
        font-family: PingFang SC Regular, PingFang SC Regular-Regular;
        font-weight: 400;
        text-align: left;
        color: #1f2e4c;
        line-height: 14px;
        text-shadow: 0px 0px 20px 0px rgba(31, 45, 75, 0.1);
        width: 100%;
        .el-select {
          width: 100%;
        }
        .el-input {
          width: 100%;
        }
      }
    }
  }
}
</style>
