<template>
  <div id="nav">
    <el-row
      style="
        background-color: white;
        padding: 0px;
        margin: 0px;
        height: 60px;
        border-bottom: 1px solid #e6e6e6;
      "
      type="flex"
      align="middle"
      justify="center"
    >
      <el-col :span="4" :offset="0" style="min-width: 100px">
        <span>
          <router-link to="/"
            ><img src="@/assets/image/feed/brand.png" height="50px"
          /></router-link>
        </span>
      </el-col>
      <el-divider direction="vertical"></el-divider>
      <el-col :span="16" :offset="0" style="min-width: 100px">
        <el-menu
          mode="horizontal"
          text-color="#606266"
          :default-active="activePathFn"
          router
        >
          <el-menu-item index="/">首页</el-menu-item>
          <!-- <el-menu-item index="/console/management">管理控制台</el-menu-item>
          <el-menu-item index="/console/scan">区块链浏览器</el-menu-item> -->
          <el-submenu index="/console/baas/displayboard">
            <template slot="title">产品服务</template>
            <el-submenu index="2-1">
              <template slot="title">基础服务</template>
              <el-menu-item index="/console/baas/displayboard">
                域乎BaaS平台
              </el-menu-item>
              <el-menu-item index="2-1-2">浏览器服务</el-menu-item>
            </el-submenu>
            <el-submenu index="2-2">
              <template slot="title">能力服务</template>
              <el-menu-item index="/console/privacy/info">隐私计算</el-menu-item>
              <el-menu-item index="2-2-2">预言机服务</el-menu-item>
              <el-menu-item index="/console/did/info">数字身份</el-menu-item>
              <el-menu-item index="2-2-4">私钥托管</el-menu-item>
            </el-submenu>
            <el-submenu index="2-3">
              <template slot="title">联盟链服</template>
              <el-menu-item index="/console/sourcetrace">蚂蚁溯源服务</el-menu-item>
            </el-submenu>
          </el-submenu>
        </el-menu>
      </el-col>
      <el-divider direction="vertical"></el-divider>
      <el-col :span="10" style="min-width: 355px">
        <el-menu
          mode="horizontal"
          text-color="#606266"
          :default-active="activePathFn"
          router
        >
          <el-menu-item index="4">帮助</el-menu-item>
          <el-submenu index="5">
            <template slot="title">服务与支持</template>
            <el-menu-item index="5-1">自主服务中心</el-menu-item>
            <el-menu-item index="5-2">常见问题中心</el-menu-item>
            <el-menu-item index="5-3">问答社区</el-menu-item>
            <el-menu-item index="5-4">服务公告</el-menu-item>
          </el-submenu>
          <el-submenu index="6">
            <template slot="title">费用</template>
            <el-menu-item index="6-1">费用问题</el-menu-item>
          </el-submenu>
          <el-menu-item @click.native.prevent="signOut"> 登出 </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  computed: {
    activePathFn() {
      return this.$route.path;
    },
  },
  methods: {
    signOut() {
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('userName');
      localStorage.removeItem('userPhone');
      window.location.href = '/';
    },
  },
});
</script>

<style scoped>
.el-menu-item.is-active {
  background-color: #ffffff !important;
  border-bottom-color: #ffffff !important;
}
</style>
