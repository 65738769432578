<template>
  <el-form
    :model="ruleForm"
    status-icon
    :rules="rules"
    ref="ruleForm"
    label-width="0px"
    class="demo-ruleForm"
  >
    <el-form-item label="" prop="pass">
      <el-input
        style="width: 100%"
        placeholder="密码"
        type="password"
        v-model="ruleForm.pass"
        autocomplete="off"
      ></el-input>
    </el-form-item>
    <el-form-item label="" prop="checkPass">
      <el-input
        style="width: 100%"
        placeholder="确认密码"
        type="password"
        v-model="ruleForm.checkPass"
        autocomplete="off"
      ></el-input>
    </el-form-item>
    <el-form-item class="form-item-submit">
      <el-button
        style="width: 100%"
        type="primary"
        @click="submitForm('ruleForm')"
        >注册</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'PassConfirm',
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      name: 'PassConfirm',
      ruleForm: {
        pass: '',
        checkPass: '',
      },
      rules: {
        pass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('submit!');
          this.$router.push('/console').catch((e) => e);
          // alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
        return true;
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style scoped>
.form-item-submit > .el-form-item__content {
  margin-left: 0 !important;
}
</style>
