<template>
  <div>
    <div class="box-top">
      <el-button @click="add">添加批次</el-button>

      <el-dialog :visible.sync="dialogVisible" width="40%" title="添加批次">
        <div>
          <div class="dialog-input">
            <div class="dialog-text">*批次号</div>
            <el-input
              v-model="addForm.sn"
              placeholder="批次号"
              class="input"
            ></el-input>
          </div>
          <div class="dialog-input">
            <div class="dialog-text">*所属商户</div>
            <el-select
              v-model="addForm.selectMerchant"
              placeholder="商户名称"
              class="input"
            >
              <el-option
                v-for="item in merchants"
                :key="item.id"
                :label="item.merchantName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="dialog-input">
            <div class="dialog-text">*商品名称</div>
            <el-select
              v-model="addForm.selectCommodity"
              placeholder="商品名称"
              class="input"
            >
              <el-option
                v-for="item in commoditis"
                :key="item.id"
                :label="item.commodityName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="dialog-input">
            <div class="dialog-text">*备注</div>
            <el-input
              type="textarea"
              v-model="addForm.desc"
              placeholder="备注"
              class="input"
            ></el-input>
          </div>
          <span slot="footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">
              确 定
            </el-button>
          </span>
        </div>
      </el-dialog>
    </div>

    <div class="box-body">
      <div>
        <el-table :data="tableData" border>
          <el-table-column prop="sn" label="批次号"> </el-table-column>
          <el-table-column prop="merchant" label="品牌商"> </el-table-column>
          <el-table-column prop="commodity" label="商品"> </el-table-column>
          <el-table-column prop="qrcodeCount" label="二维码数量">
          </el-table-column>
          <el-table-column prop="sourcetraceInfo" label="溯源信息">
            <template v-slot="scope">
              <el-button
                @click="fixSourcetrace(scope.row)"
                type="text"
                size="small"
              >
                配置
              </el-button>
              <el-button
                @click="fixSourcetrace(scope.row)"
                type="text"
                size="small"
              >
                预览
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="operation" label="操作" width="180">
            <template v-slot="scope">
              <el-button @click="submit(scope.row)" type="text" size="small">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisibleFixSourcetrace"
      width="40%"
      title="配置溯源环节"
    >
      <div>
        <div class="dialog-input">
          <div class="dialog-text">*溯源环节名称</div>
          <el-input
            v-model="fixSourcetraceForm.traceabilityLink"
            placeholder="请输入溯源环节名称"
            class="input"
          ></el-input>
        </div>
        <div class="dialog-input">
          <div class="dialog-text">*溯源环节编号</div>
          <el-input
            v-model="fixSourcetraceForm.traceabilityLinkSn"
            placeholder="溯源环节编号"
            class="input"
          ></el-input>
        </div>
        <div class="dialog-input">
          <div class="dialog-text">备注</div>
          <el-input
            type="textarea"
            v-model="fixSourcetraceForm.desc"
            placeholder="备注"
            class="input"
          ></el-input>
        </div>
        <div class="button-icon-plus">
          <el-button
            plain
            icon="el-icon-plus"
            style="width: 100%"
            @click="dialogVisibleFixSourcetrace = false"
          ></el-button>
        </div>
        <span slot="footer">
          <el-button @click="dialogVisibleFixSourcetrace = false"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="dialogVisibleFixSourcetrace = false"
          >
            确 定
          </el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          sn: '966',
          merchant: '浙江绿迹农业科技有限公司',
          brandOwner: '浙江绿迹农业科技有限公司',
          commodity: '口感型大番茄',
          qrcodeCount: 1,
          sourcetraceInfo: '',
          operation: '',
        },
      ],
      dialogVisible: false,
      merchants: [
        {
          id: 'id1',
          merchantName: '浙江绿迹农业科技有限公司',
        },
        {
          id: 'id2',
          merchantName: '上海歆香蔬果专业合作社',
        },
      ],
      commoditis: [
        {
          id: 'id1',
          commodityName: '口感型大番茄',
        },
        {
          id: 'id2',
          commodityName: '生菜',
        },
      ],
      addForm: {
        sn: '',
        selectMerchant: '',
        selectCommodity: '',
        desc: '',
      },
      fixSourcetraceForm: {
        name: '',
        traceabilityLink: '',
        traceabilityLinkSn: '',
        desc: '',
      },
      dialogVisibleFixSourcetrace: false,
    };
  },
  methods: {
    add() {
      this.dialogVisible = true;
    },
    fixSourcetrace(row) {
      console.log(row);
      this.dialogVisibleFixSourcetrace = true;
    },
  },
};
</script>

<style scoped>
.box-config {
  width: 100%;
}

.dialog-input {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-around;
  text-align: left;
}

.dialog-text {
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input {
  width: 100%;
}

.button-icon-plus {
  margin: 20px 0 20px 0;
  display: flex;
  justify-content: center;
}
</style>
