<template>
  <el-container class="box-set2">
    <div class="box-top">
      <p>XXXXX虚拟融合后 试验数据集</p>
      <p>基本分布 分箱</p>
      <el-row>
        <el-col :span="7" :offset="5">
          <el-select v-model="selectSet" placeholder="特征工程">
            <el-option label="特征工程" value="1"></el-option>
            <el-option label="自动分箱" value="2"></el-option>
            <el-option label="特征工程配置文件" value="3"></el-option>
          </el-select>
        </el-col>
        <el-col :span="5" :offset="1" style="display: flex; flex: 1">
          <el-button style="width: 100%">去建模</el-button>
        </el-col>
        <el-col :span="5" :offset="1">
          <el-select v-model="selectMore" placeholder="更多">
            <el-option label="更多" value="1"></el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>

    <el-row style="width: 100%; margin-bottom: 20px">
      <el-col :span="4"> 正例值：1 目标变量：y </el-col>
      <el-col :span="8">
        <el-input placeholder="按字段名称搜索"></el-input>
      </el-col>
    </el-row>

    <el-table :data="tableData" border class="box-body-table">
      <el-table-column prop="fieldName" label="字段名称" min-width="3">
      </el-table-column>
      <el-table-column prop="fieldType" label="字段类型" min-width="3">
      </el-table-column>
      <el-table-column prop="IV" label="IV" min-width="3"> </el-table-column>
      <el-table-column prop="img" label="分布展示" min-width="15">
      </el-table-column>
    </el-table>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      selectSet: '',
      selectMore: '',
      tableData: [
        {
          fieldName: 'fieldName',
          fieldType: 'fieldType',
          IV: 'IV',
          img: '',
        },
      ],
    };
  },
};
</script>

<style scoped>
.box-set2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.box-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}
</style>
