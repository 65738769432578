<template>
  <div :style="isAvailable">
    <div class="item">
      <el-menu-item :index="index" v-if="title == 'KMS服务'" disabled>
        <span v-if="iconType === 'fas'">
          <font-awesome-icon :icon="icon" class="icon" />
        </span>
        <span v-else>
          <inline-svg
            :src="icon"
            class="icon"
            :fill="iconColor"
          />
        </span>
        <span class="title">{{ title }}</span>
        <div class="desc-item">
          {{ desc }}
        </div>
        </el-menu-item>
        <el-menu-item :index="index" v-else>
        <span v-if="iconType === 'fas'">
          <font-awesome-icon :icon="icon" class="icon" />
        </span>
        <span v-else>
          <inline-svg
            :src="icon"
            class="icon"
            :fill="iconColor"
          />
        </span>
        <span class="title">{{ title }}</span>
        <div class="desc-item">
          {{ desc }}
        </div>
        </el-menu-item>
    </div>
  </div>
</template>

<script>
export default {
  // There will be two kind of icon type: fas or img
  props: ['icon', 'title', 'desc', 'iconType', 'enabled', 'index'],
  computed: {
    isAvailable() {
      return {
        opacity: this.enabled ? 1 : 0.9,
      };
    },
    iconColor() {
      return this.enabled ? '#599ef8' : '#aaa';
    },
  },
};
</script>

<style lang="sass" scoped>
$title-size: 16px
$icon-size: 20px
$desc-size: 15px

.icon
  padding-right: 10px
  padding-left: 5px
  height: $icon-size
  width: $icon-size

.title
  font-size: $title-size

.title-active
  color: #2285ff

.desc-item
  -webkit-line-clamp: 3 //设置行数，3行显示
  display: -webkit-box //盒子模型
  -webkit-box-orient: vertical //元素的排列方式，垂直居中
  overflow: hidden //隐藏溢出内容
  text-overflow: ellipsis //超出的内容显示省略号
  white-space: normal
  font-size: $desc-size
  margin: 7px
  opacity: 0.5
  color: #4b4a4a
  line-height: 24px

.item
  padding: 2px
  border-radius: 6px

  &:hover
    transition: all .3s ease
    box-shadow: 1px 1px 23px rgba(0, 0, 0, 0.07)

.el-menu-item
  background: rgba(0, 0, 0, 0.03)
  color: rgba(0,0,0,0)
  height: 155px !important
</style>
