<template>
  <div>
    <p class="title">{{ title }}</p>
    <el-row :gutter="20" v-for="(item, index) in data" :key="index" class="row">
      <div v-if="index % 2 === 0">
        <el-col :span="12" :offset="0">
          <chain-item
            :icon="data[index].icon"
            :desc="data[index].desc"
            :iconType="data[index].iconType"
            :title="data[index].title"
            :enabled="data[index].enabled"
            :index="data[index].index"
          />
        </el-col>
        <el-col :span="12" :offset="0" v-if="data[index + 1]">
          <chain-item
            :icon="data[index + 1].icon"
            :desc="data[index + 1].desc"
            :iconType="data[index + 1].iconType"
            :title="data[index + 1].title"
            :enabled="data[index + 1].enabled"
            :index="data[index + 1].index"
          />
        </el-col>
      </div>
    </el-row>
  </div>
</template>

<script>
import ChainItem from './ChainItem.vue';

export default {
  props: ['data', 'title'],
  components: {
    ChainItem,
  },
};
</script>

<style scoped>
.row {
  padding-bottom: 5px;
  padding-left: 15px;
  width: 100%;
}

.title {
  color: #409eff;
  padding-bottom: 10px;
  font-size: medium;
}
</style>
