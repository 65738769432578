<template>
  <div class="node-body">
    <div style="margin-bottom: 20px; display: flex; justify-content: flex-end">
      <Button text="添加应用" type="blue" @click="dialogVisible = true" />
    </div>
    <div>
      <el-table :data="tableData" border>
        <el-table-column prop="name" label="应用名称" min-width="30%">
        </el-table-column>
        <el-table-column prop="desc" label="描述" min-width="20%">
        </el-table-column>
        <el-table-column prop="baseChain" label="基础链" min-width="25%">
        </el-table-column>
        <el-table-column prop="operation" label="操作" min-width="25%">
          <template v-slot="scope">
            <Button
              @click="submit(scope.row)"
              type="mini-blue"
              size="small"
              text="详情"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="bottom">
      <Pagination />
    </div>

    <Dialog
        :visible="dialogVisible"
        :title="'添加应用'"
        @dialogClose="dialogVisible = false"
        :size="'mini'"
      >
        <el-form
          ref="form"
          :model="formAddApp"
          label-width="auto"
          label-position="left"
        >
          <el-form-item label="*应用类型">
            <el-select v-model="formAddApp.type" placeholder="应用程序">
              <el-option label="应用程序" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="*底层区块链">
            <el-select v-model="formAddApp.blockchain" placeholder="合约链">
              <el-option label="合约链" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="*应用名称">
            <el-input v-model="formAddApp.name" ></el-input>
          </el-form-item>
          <el-form-item label="*技术栈">
            <el-select
              v-model="formAddApp.tech"
              placeholder="SpringBoot(Maven)"
            >
              <el-option label="SpringBoot(Maven)" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Maven GroupID">
            <el-input v-model="formAddApp.GroupID" ></el-input>
          </el-form-item>
          <el-form-item label="Maven ArtifactID">
            <el-input v-model="formAddApp.ArtifactID" ></el-input>
          </el-form-item>
          <el-form-item label="提示">
            <el-input v-model="formAddApp.reminder" ></el-input>
          </el-form-item>
        </el-form>
        <span style="display: flex; justify-content: flex-end">
          <Button type="white" @click="dialogVisible = false" text="提交" />
        </span>
      </Dialog>
  </div>
</template>

<script>
import { Pagination, Button, Dialog } from '@/components/common/Index';

export default {
  components: {
    Pagination,
    Button,
    Dialog,
  },
  data() {
    return {
      active: 1,
      activeIndex: '1',
      tableData: [
        {
          name: 'XXXXX',
          desc: 'XXXXXXXX',
          baseChain: '合约链',
          operation: '',
        },
      ],
      dialogVisible: false,
      formAddApp: {
        type: '',
        blockchain: '',
        name: '',
        tech: '',
        GroupID: '',
        ArtifactID: '',
        reminder: '',
      },
    };
  },
  methods: {
    handleSelect(key) {
      this.active = key;
    },
    submit(row) {
      console.log(row);
    },
  },
};
</script>

<style scoped>
.node-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bottom {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
</style>
