<template>
  <div class="box-node">
    <el-row style="margin: 20px 0; display: flex; align-item: center">
      <Button
        text="节点详情"
        :type="active === 1 ? 'white' : 'blue'"
        @click="handleSelect(1)"
        class="top-button"
      />
      <Button
        text="节点申请管理"
        :type="active === 2 ? 'white' : 'blue'"
        @click="handleSelect(2)"
        class="top-button"
      />
      <Button
        text="节点白名单"
        :type="active === 3 ? 'white' : 'blue'"
        @click="handleSelect(3)"
        class="top-button"
      />
    </el-row>
    <div class="node-body">
      <Info v-if="this.active == 1" />
      <Apply v-if="this.active == 2" />
      <Whitelist v-else-if="this.active == 3" />
      <div v-else></div>
    </div>
  </div>
</template>

<script>
import { Button } from '@/components/common/Index';
import Info from './Info.vue';
import Apply from './Apply.vue';
import Whitelist from './Whitelist.vue';
// import NodeEchart from './NodeEchart.vue';

export default {
  data() {
    return {
      active: 1,
      activeIndex: '1',
    };
  },
  components: {
    Button,
    Info,
    Apply,
    Whitelist,
    // NodeEchart,
  },
  methods: {
    handleSelect(key) {
      this.active = key;
    },
  },
};
</script>

<style scoped>
.box-node {
  width: 100%;
}
.node-body {
  flex: 1;
}
.top-button {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
</style>
