<template>
  <div class="box">
    <!-- <div class="box-top">
      <strong class="top-text">联盟链管理</strong>
      <el-button @click="buy" class="top-button">新增购买</el-button>
    </div> -->
    <div>
      <el-menu
        :active="$route.path"
        mode="horizontal"
        :router="true"
        active-text-color="#409EFF"
      >
        <el-menu-item index="node"> 节点管理 </el-menu-item>
        <el-menu-item index="config"> 配置管理 </el-menu-item>
        <el-menu-item index="contract">合约管理</el-menu-item>
        <el-menu-item index="account">账户管理</el-menu-item>
        <el-menu-item index="application">应用服务</el-menu-item>
        <el-menu-item index="crosschain">跨链服务</el-menu-item>
        <el-menu-item index="oracle">预言机服务</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaaSGovernanceMenu',
  data() {
    return {};
  },
  methods: {
    buy() {
      // 新增购买
      this.$router
        .push({
          name: 'Form',
          query: {
            id: this.$route.matched.slice(-2)[0].props.default.formId,
            groupName: this.$route.matched.slice(-2)[0].props.default.title,
          },
        })
        .catch((e) => e);
    },
  },
};
</script>

<style scoped>
.box {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.box-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 38px;
  margin: 6px 0;
}
.top-text {
  margin-top: 6px;
  margin-left: 30px;
}
.top-button {
  margin-right: 30px;
}
</style>
