<template>
  <div>
    <div class="box-top">
      <el-input
        v-model="inputForm"
        placeholder="请输入姓名或登录名"
        class="top-input"
        suffix-icon="el-icon-search"
      ></el-input>
    </div>
    <div>
      <el-table :data="tableData" border>
        <el-table-column prop="name" label="成员名称" width="120">
        </el-table-column>
        <el-table-column prop="account" label="成员账户"> </el-table-column>
        <el-table-column prop="contactPerson" label="联系人" width="120">
        </el-table-column>
        <el-table-column prop="contactWay" label="联系方式"> </el-table-column>
        <el-table-column prop="createDate" label="创建时间"> </el-table-column>
        <el-table-column prop="permissionType" label="权限类型">
        </el-table-column>
        <el-table-column prop="desc" label="备注"> </el-table-column>
        <el-table-column prop="state" label="状态"> </el-table-column>
        <el-table-column prop="operation" label="操作" width="120">
          <template v-slot="scope">
            <el-button @click="submit(scope.row)" type="text" size="small">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          name: '张三',
          account: 'zhangsan01',
          contactPerson: '李四',
          contactWay: '15957130485',
          createDate: '2020-09-22',
          permissionType: '管理员',
          desc: '',
          state: '',
          operation: '',
        },
      ],
      inputForm: '',
    };
  },
  methods: {
    submit(row) {
      console.log(row);
    },
  },
};
</script>

<style scoped>
.box-top {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 6px 0;
}

.top-text {
  margin-top: 6px;
}

.top-input {
  width: 300px;
}
</style>
