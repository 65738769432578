<template>
  <div>
    <div class="top">
      <el-menu mode="horizontal" :default-active="$route.path" class="menu">
        <el-menu-item
          class="menu-item"
          v-for="value in navList.slice(0, 6)"
          :key="value.id"
          :index="value.id"
        >
          <template slot="title">
            <span>{{ value.title }}</span>
          </template>
        </el-menu-item>
      </el-menu>
      <Button @click="addOrg" text="添加机构" />
    </div>

    <el-table :data="nodeOrgList" border>
      <el-table-column prop="orgName" label="申请机构名称" min-width="30%">
      </el-table-column>
      <el-table-column prop="name" label="操作人" min-width="20%">
      </el-table-column>
      <el-table-column prop="date" label="操作时间" min-width="30%">
      </el-table-column>
      <el-table-column prop="operation" label="操作" min-width="20%">
        <template v-slot="scope">
          <Button
            @click="edit(scope.row)"
            type="mini-blue"
            style="margin-right: 20px"
          >
            编辑
          </Button>
          <Button type="mini-red" @click="remove(scope.row)"> 删除 </Button>
        </template>
      </el-table-column>
    </el-table>
    <Dialog
      title="添加机构"
      :visible.sync="dialogVisible"
      @dialogClose="dialogVisible = false"
    >
      <el-form
        ref="form"
        :model="addOrgDialog"
        label-width="auto"
        label-position="left"
      >
        <el-form-item label="选择机构">
          <el-select
            v-model="addOrgDialog.selectOrg"
            placeholder="选择机构"
            class="form-select"
          >
            <el-option
              v-for="item in addOrgDialog.list"
              :key="item.nodeId"
              :label="item.orgName"
              :value="item.nodeId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择节点">
          <el-input
            v-model="addOrgDialog.searchNode"
            placeholder="节点名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="margin-top: 15px">
        <el-checkbox-group
          v-model="addOrgDialog.selectNode"
          style="text-align: left"
        >
          <el-checkbox
            v-for="value in navList"
            :key="value.id"
            :label="value.title"
          ></el-checkbox>
        </el-checkbox-group>
      </div>
      <span style="display: flex; justify-content: center; margin-top: 20px">
        <Button @click="dialogVisible = false" style="margin-right: 20px">
          取 消
        </Button>
        <Button type="primary" @click="dialogVisible = false"> 保 存 </Button>
      </span>
    </Dialog>
  </div>
</template>

<script>
import { Button, Dialog } from '@/components/common/Index';

export default {
  components: {
    Button,
    Dialog,
  },
  data() {
    return {
      dialogVisible: false,
      navList: [
        {
          id: 'node1',
          title: '云节点1',
        },
        {
          id: 'node2',
          title: '云节点2',
        },
        {
          id: 'node3',
          title: '云节点3',
        },
        {
          id: 'node4',
          title: '云节点4',
        },
        {
          id: 'node5',
          title: '云节点5',
        },
        {
          id: 'node6',
          title: '云节点6',
        },
        {
          id: 'node7',
          title: '云节点7',
        },
        {
          id: 'node8',
          title: '云节点8',
        },
      ],
      nodeOrgList: [
        {
          nodeId: 'node1', // 节点id
          orgName: 'orgName申请机构名称',
          name: 'name操作人',
          date: '1970-01-01 00:00:00',
          operation: 'operation操作',
        },
      ],
      addOrgDialog: {
        selectOrg: '',
        list: [
          {
            nodeId: 'node1',
            orgName: 'orgName申请机构名称',
          },
          {
            nodeId: 'node2',
            orgName: 'node2',
          },
        ],
        selectNode: [],
        searchNode: '',
      },
    };
  },
  methods: {
    addOrg() {
      this.dialogVisible = true;
    },
    edit(row) {
      console.log(row);
    },
    remove(row) {
      console.log(row);
    },
    handleClose(done) {
      done();
    },
  },
};
</script>

<style scoped lang='scss'>
.box-whitelist {
  display: flex;
  justify-content: space-between;
}
.form-box {
  text-align: start;
}
.form-select {
  width: 100%;
}

.menu {
  border-bottom: 0px;
  .el-menu-item {
    font-size: 16px !important;
    font-family: PingFang SC Medium, PingFang SC Medium-Medium;
    font-weight: 500;
  }
  .el-menu-item.is-active {
    border-bottom: 0px;
  }
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
