<template>
  <div>
    <el-table :data="accountManagement" border>
      <el-table-column prop="address" label="账户名称" min-width="20%">
      </el-table-column>
      <el-table-column prop="address" label="账户地址" min-width="30%">
      </el-table-column>
      <el-table-column prop="publicKey" label="账户公钥" min-width="30%">
      </el-table-column>
      <el-table-column prop="restorePublicKey" label="账户恢复公钥" min-width="20%">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accountManagement: [
        {
          name: 'name账户名称',
          address: 'address账户地址',
          publicKey: 'publicKey账户公钥',
          restorePublicKey: 'restorePublicKey账户恢复公钥',
        },
      ],
    };
  },
};
</script>
