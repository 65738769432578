<template>
  <el-container class="box">
    <div class="box-top">
      <p>XXXXX虚拟融合后 试验数据集</p>
      <p>基本分布</p>
      <el-row class="box-body-text">
        <el-col :span="7" :offset="5">
          <el-select v-model="selectSet" placeholder="特征工程">
            <el-option label="特征工程" value="1"></el-option>
            <el-option label="自动分箱" value="2"></el-option>
            <el-option label="特征工程配置文件" value="3"></el-option>
          </el-select>
        </el-col>
        <el-col :span="5" :offset="1">
          <el-button style="width: 100%">去建模</el-button>
        </el-col>
        <el-col :span="5" :offset="1">
          <el-select v-model="selectMore" placeholder="更多">
            <el-option label="更多" value="1"></el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>

    <el-table :data="tableData" border class="box-body-table">
      <el-table-column prop="fieldName" label="字段名称" min-width="3">
      </el-table-column>
      <el-table-column prop="fieldType" label="字段类型" min-width="3">
      </el-table-column>
      <el-table-column prop="fieldDesc" label="字段说明" min-width="3">
      </el-table-column>
      <el-table-column prop="recordNum" label="记录数" min-width="3">
      </el-table-column>
      <el-table-column prop="missingValueNum" label="缺失值数量" min-width="3">
      </el-table-column>
      <el-table-column prop="img" label="分布图" min-width="9">
      </el-table-column>
    </el-table>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      selectSet: '',
      selectMore: '',
      tableData: [
        {
          fieldName: 'fieldName',
          fieldType: 'fieldType',
          fieldDesc: 'fieldDesc',
          recordNum: 1,
          missingValueNum: 1,
          img: '',
        },
      ],
    };
  },
};
</script>

<style scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.box-top {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}
</style>
