<template>
  <div>
    <el-table :data="nodeApplyList" border>
      <el-table-column prop="orgName" label="申请机构名称" width="180">
      </el-table-column>
      <el-table-column prop="name" label="申请人" width="100">
      </el-table-column>
      <el-table-column prop="reason" label="申请原因"> </el-table-column>
      <el-table-column prop="date" label="申请时间" width="180">
      </el-table-column>
      <el-table-column prop="approveStatus" label="审批状态" width="80">
      </el-table-column>
      <el-table-column prop="approver" label="审批人" width="100">
      </el-table-column>
      <el-table-column prop="approveDate" label="审批时间" width="180">
      </el-table-column>
      <el-table-column prop="operation" label="操作" width="120">
        <template v-slot="scope">
          <Button @click="submit(scope.row)" type="mini-blue">
            提交工单
          </Button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { Button } from '@/components/common/Index';

export default {
  components: {
    Button,
  },
  data() {
    return {
      nodeApplyList: [
        {
          orgName: 'orgName申请机构名称',
          name: 'name申请人',
          reason: 'reason申请原因',
          date: '1970-01-01 00:00:00',
          approveStatus: 'approveStatus审批状态',
          approver: 'approver审批人',
          approveDate: '1970-01-01 00:00:00',
          operation: 'operation操作',
        },
      ],
    };
  },
  methods: {
    submit(row) {
      console.log('submit:', row);
    },
  },
};
</script>
