<template>
  <div class="box">
    <el-popover>
      <div class="header">
        <div class="col-wdit">xxx数据集</div>
        <div class="col-func">
          <div class="desc">虚拟融合后 试验数据集</div>
          <div class="select">
            <el-dropdown>
              <el-button type="primary">
                特征工程<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>自动分箱</el-dropdown-item>
                <el-dropdown-item>特征工程配置文件</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button>去建模</el-button>
            <el-dropdown>
              <el-button type="primary">
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
            </el-dropdown>
          </div>
        </div>
        <div></div>
      </div>
      <div class="content">
        <div>
            <div></div>
            <div>
                <el-input placeholder="请输入内容" v-model="input5" class="input-with-select">
                </el-input>
            </div>
        </div>
        <el-table :data="gridData">
          <el-table-column
            width="150"
            property="names"
            label="字段名称"
          ></el-table-column>
          <el-table-column
            width="100"
            property="type"
            label="字段类型"
          ></el-table-column>
          <el-table-column
            width="100"
            property="type"
            label="IV"
          ></el-table-column>
          <el-table-column
            width="300"
            property="chart"
            label="分布图"
          ></el-table-column>
        </el-table>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gridData: [
        {
          names: 'id',
          type: 'integer',
          description: 'id',
          number: '150',
          lackNum: '150',
          chart: '',
        },
      ],
      input5: '',
    };
  },
};
</script>

<style scoped>
.col-func {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>
