<template>
  <div>
    <el-container class="box-displayboard-top">
      <el-row style="width: 100%">
        <el-col :span="20">
          <el-menu
            default-active="1"
            mode="horizontal"
            @select="handleSelect"
            class="menu"
          >
            <el-menu-item index="1"> 全部项目 </el-menu-item>
            <el-menu-item index="2"> 发起的项目 </el-menu-item>
            <el-menu-item index="3"> 参与的项目 </el-menu-item>
          </el-menu>
        </el-col>
        <el-col :span="4" style="height: 100%">
          <div style="display: flex; justify-content: flex-end">
            <el-button @click="addOrJoin"> 新建项目 </el-button>
          </div>
          <!-- <el-menu
            default-active="2"
            mode="horizontal"
            @select="handleSelect"
            style="min-width: 138px; display: flex; justify-content: flex-end"
            class="menu"
          >
            <el-menu-item style="padding: 0">
              <el-button @click="addOrJoin"> 新建项目 </el-button>
            </el-menu-item>
          </el-menu> -->
        </el-col>
      </el-row>
      <div class="top-input" style="margin-bottom: 10px">
        <div>
          <el-input
            v-model="searchProject"
            suffix-icon="el-icon-search"
          ></el-input>
        </div>
        <span>全部项目3个</span>
      </div>

      <el-dialog :visible.sync="dialogVisible1" width="40%" title="新建项目">
        <div>
          <div class="dialog1-input">
            <div class="dialog1-text">项目名称：</div>
            <div style="width: 75%">
              <el-input
                v-model="addForm.name"
                placeholder="给项目定义一个名称"
              ></el-input>
            </div>
          </div>
          <div class="dialog1-input">
            <div class="dialog1-text">项目简介：</div>
            <div style="width: 75%">
              <el-input
                type="textarea"
                v-model="addForm.decs"
                placeholder="该项目需求的简要描述"
              ></el-input>
            </div>
          </div>
          <div class="dialog1-input">
            <div class="dialog1-text">成员：</div>
            <el-tag
              v-for="(tag, index) in addForm.memberArray"
              :key="index"
              closable
              style="margin-right: 3px"
            >
              {{ tag.memberName }}
            </el-tag>
          </div>
          <el-col
            :offset="6"
            style="margin-bottom: 15px; display: flex; justify-content: flex-start"
          >
            <el-button
              plain
              icon="el-icon-plus"
              style="width: 25%"
              type="text"
              @click="dialogVisibleFixSourcetrace = false"
            >
              添加成员
            </el-button>
            <!-- <el-checkbox-group
              v-model="addForm.selectMember"
              style="text-align: left"
            >
              <el-checkbox
                v-for="(value, index) in allMemberArray"
                :key="index"
                :label="value.memberName"
              ></el-checkbox>
            </el-checkbox-group> -->
          </el-col>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible1 = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible1 = false">
              创 建
            </el-button>
          </span>
        </div>
      </el-dialog>
    </el-container>
    <el-container>
      <el-row style="width: 100%">
        <el-col
          :span="7"
          v-for="(item, index) in tableData"
          :key="index"
          :offset="1"
          :style="index % 3 === 0 ? 'margin-left: 0px' : ''"
        >
          <el-card
            :body-style="{ padding: '0px' }"
            class="box-card"
            shadow="hover"
          >
            <div style="padding: 20px 20px 0 20px">
              <div class="top-input">
                <strong>{{ item.name }}</strong>
                <el-dropdown
                  trigger="click"
                  class="custom-tree-menu"
                  size="small"
                >
                  <i class="el-icon-more rotate" />
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="(event, index) in events"
                      :key="index"
                      :divided="index > 0"
                    >
                      {{ event }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <p>{{ item.desc }}</p>
              <el-tag
                v-for="(tag, index) in item.memberArray"
                :key="index"
                closable
                type=""
                style="margin-right: 3px"
              >
                {{ tag }}
              </el-tag>
              <p>
                创建时间：{{ item.ctime }} 项目成员数：
                {{ item.memberCount }}
              </p>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          id: 'id1',
          name: '标题标题',
          creator: 'creator创建者',
          memberArray: ['成员1', '成员2'],
          memberCount: 7,
          ctime: '2021-01-01',
          desc: 'desc描述描述描述描述描述描述描述描述描述',
        },
        {
          id: 'id2',
          name: '标题标题',
          creator: 'creator创建者',
          memberArray: ['成员1', '成员2'],
          memberCount: 7,
          ctime: '2021-01-01',
          desc: 'desc描述描述描述描述描述描述描述描述描述',
        },
      ],
      allMemberArray: [
        {
          id: 'id1',
          memberName: '成员1',
        },
        {
          id: 'id2',
          memberName: '成员2',
        },
      ],
      dialogVisible1: false,
      addForm: {
        name: '',
        desc: '',
        memberArray: [
          {
            id: 'id1',
            memberName: '成员1',
          },
          {
            id: 'id2',
            memberName: '成员2',
          },
        ],
        selectMember: [],
      },
      searchProject: '',
      selectType: '',
      active: 1,
      events: ['项目设置', '删除项目'],
    };
  },
  methods: {
    handleSelect(key) {
      this.active = key;
    },
    addOrJoin() {
      this.dialogVisible1 = true;
    },
  },
};
</script>

<style scoped>
.box-displayboard-top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.top-text {
  margin-top: 6px;
  margin-left: 0px;
}

.top-input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.box2 {
  display: flex;
  flex-wrap: wrap;
}

.box-card {
  margin-bottom: 20px;
  padding-bottom: 0px;
  text-align: start;
}

.dialog1-input {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
}

.dialog1-text {
  width: 25%;
  text-align: left;
}
</style>
