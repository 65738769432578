import { render, staticRenderFns } from "./Whitelist.vue?vue&type=template&id=6885a415&scoped=true&"
import script from "./Whitelist.vue?vue&type=script&lang=js&"
export * from "./Whitelist.vue?vue&type=script&lang=js&"
import style0 from "./Whitelist.vue?vue&type=style&index=0&id=6885a415&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6885a415",
  null
  
)

export default component.exports