<script>
import Carousel from './Carousel.vue';
import Video from './Video.vue';
import Banner from './Banner.vue';
import Pagination from './Pagination.vue';
import Button from './Button.vue';
import Dialog from './Dialog.vue';

export {
  Carousel, Video, Banner, Pagination, Button, Dialog,
};

export default {};
</script>
