import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
import { InlineSvgPlugin } from 'vue-inline-svg';
import VueQr from 'vue-qr';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueFullPage from 'vue-fullpage.js';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCopyright,
  faMailBulk,
  faMapMarker,
  faCertificate,
  faCloudDownloadAlt,
  faSlidersH,
  faEnvelope,
  faSyncAlt,
  faMobileAlt,
  faHeadphonesAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ECharts from 'vue-echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import 'echarts-gl';

import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router/index';
import store from './store/index';

library.add(faCopyright);
library.add(faMailBulk);
library.add(faMapMarker);
library.add(faCertificate);
library.add(faCloudDownloadAlt);
library.add(faEnvelope);
library.add(faSlidersH);
library.add(faSyncAlt);
library.add(faMobileAlt);
library.add(faHeadphonesAlt);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('v-echart', ECharts);
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueAxios, axios);
Vue.use(VueFullPage);
Vue.use(VueQr);
Vue.use(InlineSvgPlugin);
axios.defaults.baseURL = '/api';
axios.defaults.headers.post['Content-Type'] = 'application/json';
router.beforeEach((to, from, next) => {
  console.log(to.meta.content);
  if (to.meta.content) {
    const head = document.getElementsByTagName('head');
    const meta = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords);
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description);
    meta.content = to.meta.content;
    head[0].appendChild(meta);
  }
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
