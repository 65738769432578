<template>
  <div id="app">
    <el-container>
      <Header class="nav" />
      <el-container>
        <el-aside width="200px" v-if="$route.meta.NavMenuType === 'Account'">
          <AccountNavMenu />
        </el-aside>
        <el-aside width="200px" v-else-if="$route.meta.NavMenuType === 'BaaS'">
          <BaaSNavMenu />
        </el-aside>
        <el-aside
          width="200px"
          v-else-if="$route.meta.NavMenuType === 'SourceTrace'"
        >
          <SourceTraceNavMenu />
        </el-aside>
        <el-aside
          width="200px"
          v-else-if="$route.meta.NavMenuType === 'Privacy'"
        >
          <PrivacyNavMenu />
        </el-aside>
        <el-aside width="200px" v-else-if="$route.meta.NavMenuType === 'DID'">
          <DIDNavMenu />
        </el-aside>
        <el-main><router-view /></el-main>
      </el-container>
      <el-footer height="360px"><Footer /></el-footer>
    </el-container>
  </div>
</template>

<script>
import Vue from 'vue';
import Header from './components/header/Index.vue';
import Footer from './components/Footer.vue';
import {
  AccountNavMenu,
  BaaSNavMenu,
  SourceTraceNavMenu,
  PrivacyNavMenu,
  DIDNavMenu,
} from './components/console/Index.vue';
import '@/assets/css/font.css';

export default Vue.extend({
  data() {
    return {
      choiceTitle: '',
    };
  },
  components: {
    Header,
    Footer,
    AccountNavMenu,
    BaaSNavMenu,
    SourceTraceNavMenu,
    PrivacyNavMenu,
    DIDNavMenu,
  },
  computed: {
    activePathFn() {
      return this.$route.path;
    },
  },
  mounted() {
    window.scroll(0, 0);
  },
  updated() {
    window.scroll(0, 0);
  },
});
</script>

<style>
#app {
  display: flex;
  font-family: SourceHan, PingFang, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: calc(100vh + 360px);
  flex-direction: column;
  justify-content: space-between;
}

body {
  margin: 0 !important;
}

.console {
  display: flex;
  justify-content: space-around;
  flex: 1;
}

.el-header {
  padding: 0;
}

.el-main {
  padding: 0;
  overflow: hidden;
}

.el-footer {
  padding: 0;
}
</style>
