<template>
  <div>
    <el-carousel
      v-if="images"
      v-bind:height="height"
      type="default"
      direction="horizontal"
      :initial-index="0"
      :autoplay="true"
      :interval="3000"
      :loop="true"
      trigger="hover"
      indicator-position="outside"
      arrow="hover"
    >
      <el-carousel-item v-for="image in images" :key="image" class="image_type">
        <el-image :src="image" fit="cover" class="image_type" />
      </el-carousel-item>
    </el-carousel>
    <el-carousel v-else height="700px">
      <el-carousel-item v-for="item in 4" :key="item">
        <h3 class="small">{{ item }}</h3>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: ['height', 'images'],
  data() {
    return {};
  },
});
</script>

<style scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.image_type {
  width: 100%;
}
</style>
