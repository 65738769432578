<template>
  <el-row class="tac">
    <el-col class="menu">
      <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        background-color="#FFFFFF"
        text-color="#1F2E4D"
        active-text-color="#1F2E4D"
        :router="true"
      >
        <el-menu-item index="/console/privacy">
          <i class="el-icon-menu"></i>
          <span slot="title">概览</span>
        </el-menu-item>
        <el-menu-item index="/console/privacy/project">
          <i class="el-icon-menu"></i>
          <span slot="title">我的项目</span>
        </el-menu-item>
        <!-- <el-menu-item index="/console/privacy/data">
          <i class="el-icon-menu"></i>
          <span slot="title">我的数据</span>
        </el-menu-item> -->
        <el-submenu index="">
          <template slot="title">
            <i class="el-icon-setting"></i>
            <span class="submenu-text">我的数据</span>
          </template>
          <el-menu-item index="/console/privacy/data/management">
            数据管理
          </el-menu-item>
          <el-menu-item index="/console/privacy/data/security">
            安全服务
          </el-menu-item>
          <el-menu-item index="/console/privacy/data/model">
            模型服务
          </el-menu-item>
          <el-menu-item index="/console/privacy/data/configmodel">
            配置模型
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="/console/privacy/monitor">
          <i class="el-icon-menu"></i>
          <span slot="title">监控分析</span>
        </el-menu-item>
      </el-menu>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'PrivacyNavMenu',
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style scoped>
.tac {
  height: 100%;
}

.menu {
  height: 100%;
}

.el-menu-vertical-demo {
  height: 100%;
}

.el-menu-item:hover {
  background-color: #2285ff !important;
  color: #ffffff !important;
}

.el-menu-item {
  text-align: left;
  font-size: 16px;
  font-family: PingFang SC Regular;
}

.el-menu {
  border-right: 0px !important;
}

.el-submenu {
  text-align: left;
}
.submenu-text {
  font-size: 16px;
  font-family: PingFang SC Regular;
}
</style>
