<template>
  <el-container style="height: 100%">
    <el-container class="box">
      <div class="box-displayboard">
        <div class="box-displayboard-top1">
          <span class="top-text">我的联盟</span>
          <span style="display: flex; align-items: center">
            <el-select
              v-model="selectType"
              placeholder="我的联盟"
              class="top-select"
            >
              <el-option label="我的联盟" value="added"></el-option>
              <el-option label="我加入的" value="joined"></el-option>
            </el-select>
            <Button @click="addOrJoin" text="创建/加入" type="blue" />
            <!-- <el-button @click="addOrJoin"> 创建/加入 </el-button> -->
          </span>

          <el-dialog :visible.sync="dialogVisible1" width="30%">
            <div class="dialog1">
              <div class="dialog-button">
                <el-button @click="jumpToAdd" class="button">
                  创 建 联 盟
                </el-button>
              </div>
              <div class="dialog-button">
                <el-button @click="jumpToJoin" class="button">
                  加 入 联 盟
                </el-button>
              </div>
            </div>
          </el-dialog>

          <el-dialog
            :visible.sync="dialogVisible2"
            width="40%"
            title="创建联盟"
          >
            <div>
              <div class="dialog2-input">
                <div class="dialog2-text">*联盟名称</div>
                <el-input
                  v-model="addForm.name"
                  placeholder="联盟名称"
                  class="input"
                ></el-input>
              </div>
              <div class="dialog2-input">
                <div class="dialog2-text">*联盟描述</div>
                <el-input
                  type="textarea"
                  v-model="addForm.decs"
                  placeholder="联盟描述"
                  class="input"
                ></el-input>
              </div>
              <div class="dialog2-input">
                <div class="dialog2-text">*联系人</div>
                <el-input
                  v-model="addForm.contacts"
                  placeholder="联系人"
                  class="input"
                ></el-input>
              </div>
              <div class="dialog2-input">
                <div class="dialog2-text">*联系电话</div>
                <el-input
                  v-model="addForm.contactPhone"
                  placeholder="联系电话"
                  class="input"
                ></el-input>
              </div>
              <div class="dialog2-input">
                <div class="dialog2-text">*联系邮箱</div>
                <el-input
                  v-model="addForm.contactEmail"
                  placeholder="联系邮箱"
                  class="input"
                ></el-input>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible2 = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible2 = false">
                  创 建
                </el-button>
              </span>
            </div>
          </el-dialog>

          <el-dialog
            :visible.sync="dialogVisible3"
            width="40%"
            title="加入联盟"
          >
            <div class="dialog2">
              <div class="dialog2-input">
                <div class="dialog2-text">*邀请码</div>
                <el-input
                  v-model="joinForm.invitationCode"
                  placeholder="邀请码"
                  class="input"
                ></el-input>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible3 = false"> 提 交 </el-button>
              </span>
            </div>
          </el-dialog>
        </div>
        <el-container>
          <el-row style="width: 100%">
            <el-col
              class="card-col"
              v-for="(item, index) in tableData"
              :key="index"
              :style="index % 3 === 0 ? '' : 'margin-left: 20px'"
            >
              <el-card
                class="box-card"
                shadow="hover"
                @click.native="goto(item)"
              >
                <div class="info-top">
                  <div class="info-top-text">
                    <strong>[我加入的]{{ item.name }}</strong>
                    <p>创建者：{{ item.creator }}</p>
                    <p>区块链数量：{{ item.chainCount }}</p>
                    <p>
                      节点数量：{{ item.nodeCount[0] }}/{{ item.nodeCount[1] }}
                    </p>
                    <p>描述：{{ item.desc }}</p>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-container>
      </div>
    </el-container>
  </el-container>
</template>

<script>
import { Button } from '@/components/common/Index';

export default {
  /* name: 'BaaSDisplayBoard', */
  components: {
    Button,
  },
  data() {
    return {
      tableData: [
        {
          id: 'baasId1',
          name: 'name联盟名称',
          creator: 'creator创建者',
          chainCount: 7,
          nodeCount: [5, 5],
          type: 'joined', // joined加入的，added创建的
          desc: 'desc描述',
        },
        {
          id: 'baasId2',
          name: 'name联盟名称',
          creator: 'creator创建者',
          chainCount: 7,
          nodeCount: [5, 5],
          type: 'joined',
          desc: 'desc描述',
        },
        {
          id: 'baasId3',
          name: 'name联盟名称',
          creator: 'creator创建者',
          chainCount: 7,
          nodeCount: [5, 5],
          type: 'joined',
          desc: 'desc描述',
        },
        {
          id: 'baasId4',
          name: 'name联盟名称',
          creator: 'creator创建者',
          chainCount: 7,
          nodeCount: [5, 5],
          type: 'joined',
          desc: 'desc描述',
        },
        {
          id: 'baasId5',
          name: 'name联盟名称',
          creator: 'creator创建者',
          chainCount: 0,
          nodeCount: [5, 5],
          type: 'joined',
          desc: 'desc描述',
        },
        {
          id: 'baasId6',
          name: 'name联盟名称',
          creator: 'creator创建者',
          chainCount: 0,
          nodeCount: [2, 5],
          type: 'joined',
          desc: 'desc描述',
        },
      ],
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      addForm: {
        name: '',
        desc: '',
        contacts: '',
        contactPhone: '',
        contactEmail: '',
      },
      joinForm: {
        invitationCode: '',
      },
      selectType: '',
    };
  },
  methods: {
    addOrJoin() {
      this.dialogVisible1 = true;
    },
    jumpToAdd() {
      this.dialogVisible1 = false;
      this.dialogVisible2 = true;
      this.dialogVisible3 = false;
    },
    jumpToJoin() {
      this.dialogVisible1 = false;
      this.dialogVisible2 = false;
      this.dialogVisible3 = true;
    },
    goto(item) {
      this.$router
        .push({ name: '联盟链管理', params: { baasId: item.id } })
        .catch((e) => e);
    },
  },
};
</script>

<style scoped>
.box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  margin-top: 30px;
  background: #ffffff;
}
.box-displayboard {
  width: 78%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.box-displayboard-top1 {
  display: flex;
  justify-content: space-between;
  width: 96%;
  margin: 40px 0;
  align-items: center;
}

.card-col {
  width: calc((100% - 40px) / 3);
}

.top-text {
  margin-top: 6px;
  margin-left: 0px;
  font-size: 24px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 18px;
}

.top-select {
  margin-right: 20px;
}

.box2 {
  display: flex;
  flex-wrap: wrap;
}

.box-card {
  height: 220px;
  margin-bottom: 20px;
  text-align: start;
  /* min-width: 300px; */
}

.dialog1 {
  display: flex;
  justify-content: space-around;
  height: 300px;
}

.dialog-button {
  height: 100%;
  width: 50%;
}

.button {
  display: flex;
  flex: 1;
  height: 80%;
  width: 90%;
  justify-content: center;
  align-items: center;
}

.dialog2-input {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-around;
  text-align: left;
}

.dialog2-text {
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
