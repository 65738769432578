<template>
  <div>
    <div class="box-top">
      <el-button @click="add">添加商户</el-button>

      <el-dialog
        :visible.sync="dialogVisible"
        width="40%"
        title="添加二维码规则"
      >
        <div>
          <div class="dialog-input">
            <div class="dialog-text">*商户名称</div>
            <el-input
              v-model="addForm.merchantName"
              placeholder="请输入商户名称"
              class="input"
            ></el-input>
          </div>
          <div class="dialog-input">
            <div class="dialog-text">*备注</div>
            <el-input
              type="textarea"
              v-model="addForm.desc"
              placeholder="备注"
              class="input"
            ></el-input>
          </div>
          <span slot="footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">
              确 定
            </el-button>
          </span>
        </div>
      </el-dialog>
    </div>
    <div>
      <el-table class="table" :data="tableData" border>
        <el-table-column prop="merchant" label="商户名称"> </el-table-column>
        <el-table-column prop="merchantId" label="商户标识码">
        </el-table-column>
        <el-table-column prop="desc" label="描述" width="180">
        </el-table-column>
        <el-table-column prop="status" label="企业认证状态"> </el-table-column>
        <el-table-column prop="operation" label="" width="180">
          <template v-slot="scope">
            <el-button
              @click="enterpriseCertification(scope.row)"
              type="text"
              size="small"
            >
              认证
            </el-button>
            <el-button
              @click="pushCommodity(scope.row)"
              type="text"
              size="small"
            >
              查看商品
            </el-button>
            <el-button @click="dialogVisible = true" type="text" size="small">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          merchant: '浙江绿迹农业科技有限公司',
          merchantId: '0xb76C665C62f9b3f24d447861175de1794d0e0397',
          desc: '',
          status: '未认证',
          operation: '',
        },
      ],
      dialogVisible: false,
      addForm: {
        merchantName: '',
        desc: '',
      },
    };
  },
  methods: {
    add() {
      this.dialogVisible = true;
    },
    enterpriseCertification(row) {
      console.log(row);
      this.$router.push({ name: '企业认证' }).catch((e) => e);
    },
    pushCommodity(row) {
      console.log(row);
      this.$router.push({ name: '商品管理' }).catch((e) => e);
    },
  },
};
</script>

<style scoped>
.box-config {
  width: 100%;
}

.dialog-input {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-around;
  text-align: left;
}

.dialog-text {
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input {
  width: 100%;
}
</style>
