<template>
  <div>
    <div class="box-title">
      <el-input
        v-model="searchName"
        placeholder="按数据以及名称搜索"
        style="width: 300px"
        suffix-icon="el-icon-search"
      ></el-input>
      <el-button @click="dialogVisible1 = true"> 添加数据 </el-button>

      <el-dialog :visible.sync="dialogVisible1" width="45%" title="添加数据">
        <el-row>
          <el-col :span="6">数据名称</el-col>
          <el-col :span="18">
            <el-input v-model="dialog1Form.name"></el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">数据简介</el-col>
          <el-col :span="18">
            <el-input v-model="dialog1Form.desc" type="textarea"></el-input>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="6">上传文件</el-col>
          <el-col :span="18" style="display: flex; justify-content: center">
            <el-upload
              action="https://jsonplaceholder.typicode.com/posts/"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              class="dialog-upload"
            >
              <div style="height: 100%; display: flex; flex-direction: column">
                <!-- <i class="el-icon-plus dialog-upload-text"></i> -->
                <span class="dialog-upload-text"
                  >文件支持CSV，以及CSV压缩成ZIP文件，最大支持100M</span
                >
                <span class="dialog-upload-text">文件需带英文表头</span>
              </div>
            </el-upload>
          </el-col>
        </el-row>
      </el-dialog>
    </div>
    <div>
      <div class="table">
        <el-table :data="tableData" border>
          <el-table-column prop="name" label="名称" min-width="40%">
          </el-table-column>
          <el-table-column prop="num" label="加入项目数" min-width="16%">
          </el-table-column>
          <el-table-column prop="date" label="更新日期" min-width="22%">
          </el-table-column>
          <el-table-column prop="status" label="" min-width="22%">
            <template v-slot="scope">
              <el-button
                @click="doSomething(scope.row)"
                type="text"
                size="small"
              >
                加入项目
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="configTableData" label="操作" width="180">
            <template v-slot="scope">
              <el-button
                @click="doSomething(scope.row)"
                type="text"
                size="small"
              >
                更多
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchName: '',
      tableData: [
        {
          num: 1,
          name: 'sora',
          date: '2021-01-01',
          status: 'authenticated',
        },
      ],
      dialogVisible1: false,
      dialog1Form: {
        name: '',
        desc: '',
      },
      dialogImageUrl: '',
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped>
.box-title {
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-family: PingFang SC Regular;
}

.el-row {
  margin-bottom: 20px;
}

.dialog-upload {
  width: 100%;
}
.dialog-upload >>> .el-upload {
  width: 100% !important;
}

.dialog-upload-text {
  text-align: center;
  height: 30px;
}
</style>
