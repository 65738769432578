<template>
  <div class="sticky">
    <el-header style="background: white; width: 100%">
      <div v-if="!$route.meta.NavMenuType">
        <el-row align="middle" justify="left" class="row" type="flex">
          <el-col :offset="0" :span="4" style="min-width: 100px">
            <span>
              <router-link to="/"
                ><img alt="" height="44px" src="@/assets/image/feed/brand.png"
              /></router-link>
            </span>
          </el-col>
          <el-col :offset="0" :span="15" style="overflow: hidden;border-bottom: 1px solid #e6e6e6;">
            <el-menu
              :default-active="activePathFn"
              :unique-opened="true"
              :router="true"
              mode="horizontal"
              text-color="#606266"
              style="display: flex"
            >
              <el-submenu index="/chain">
                <template slot="title">区块链服务</template>
                <div class="chain" @mouseenter="refresh">
                  <el-tabs
                    :tab-position="tabPosition"
                    style="height: 100%"
                    v-if="isTabsVisible"
                  >
                    <el-tab-pane label="基础服务" class="tab-pane-item">
                      <chain-pane :data="chainMenu.basic" title="基础服务" />
                    </el-tab-pane>
                    <el-tab-pane label="BaaS服务" class="tab-pane-item">
                      <chain-pane :data="chainMenu.baas" title="BaaS服务" />
                    </el-tab-pane>
                    <el-tab-pane label="增值服务" class="tab-pane-item">
                      <chain-pane :data="chainMenu.va" title="增值服务" />
                    </el-tab-pane>
                    <el-tab-pane label="行业链服" class="tab-pane-item">
                      <chain-pane :data="chainMenu.alliance" title="行业链服" />
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </el-submenu>
              <el-menu-item index="/appstore">应用市场</el-menu-item>
              <el-submenu index="/solution">
                <template slot="title" class="solutionBtn">解决方案</template>
                <div class="solutionBtn">
                  <el-menu-item index="/solution/digital">
                    数字消费资产解决方案
                  </el-menu-item>
                  <el-menu-item index="/solution/farming">
                    区块链+农业解决方案
                  </el-menu-item>
                  <el-menu-item index="/solution/landed">
                    区块链+地产解决方案
                  </el-menu-item>
                  <el-menu-item index="/solution/human">
                    区块链+人资解决方案
                  </el-menu-item>
                  <el-menu-item index="/solution/supplychain">
                    区块链+供应链管理解决方案
                  </el-menu-item>
                  <el-menu-item index="/solution/financial">
                    区块链+金融解决方案
                  </el-menu-item>
                  <el-menu-item index="/solution/internet">
                    区块链+物联网解决方案
                  </el-menu-item>
                </div>
              </el-submenu>
              <el-menu-item index="/audition">测评认证</el-menu-item>
              <!-- <el-submenu index="5">
                <template slot="title">服务与支持</template>
                <el-menu-item index="5-1">自主服务中心</el-menu-item>
                <el-menu-item index="5-2">常见问题中心</el-menu-item>
                <el-menu-item index="5-3">问答社区</el-menu-item>
                <el-menu-item index="5-4">服务公告</el-menu-item>
              </el-submenu> -->
              <el-menu-item index="/building" disabled>开发者</el-menu-item>
              <el-menu-item index="/about">关于我们</el-menu-item>
              <el-menu-item
                index=""
                key="https://explorer.yuhu.tech/"
                @click="goBrowser"
                >存证核验
              </el-menu-item>
            </el-menu>
          </el-col>
          <el-col :span="5" style="min-width: 200px">
            <div class="console">
              <div v-if="passToken == null">
<!--                <el-link-->
<!--                    :underline="false"-->
<!--                    style="padding-right: 14px"-->
<!--                    disabled-->
<!--                >-->
<!--                  <font-awesome-icon-->
<!--                      icon="cloud-download-alt"-->
<!--                      style="padding-right: 7px;"-->
<!--                  />-->
<!--                  应用下载-->
<!--                </el-link>-->
              </div>
              <!-- <el-dropdown v-else class="userBtn">
                <span class="el-dropdown-link">
                  {{ userName
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native.prevent="signOut"
                    >退出登录</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown> -->
              <el-menu
                :default-active="activePathFn"
                :unique-opened="true"
                :router="true"
                mode="horizontal"
                text-color="#606266"
                style="height: 100%;margin:5px;display:flex;"
                v-else
              >
                <el-submenu>
                  <template slot="title">{{ userName }}</template>
                  <el-menu-item @click.native.prevent="signOut">退出登录</el-menu-item>
                </el-submenu>
              </el-menu>
              <router-link
                :underline="false"
                to="/console/login"
                v-if="passToken == null"
              >
                <el-button size="small" type="primary" style="margin:1vw;">登录/注册</el-button>
              </router-link>
              <el-button
                  size="small"
                  type="primary"
                  style="margin: 20px 0;"
                  v-else
                  @click="goPaaS()"
              >进入控制台</el-button>
<!--              <router-link :underline="false" to="/console/display" v-else>-->
<!--                <el-button size="small" type="primary" style="margin: 20px 0;"-->
<!--                  >进入控制台</el-button-->
<!--                >-->
<!--              </router-link>-->
            </div>
          </el-col>
        </el-row>
      </div>
      <ConsoleHeader v-else />
    </el-header>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import ConsoleHeader from '@/components/console/ConsoleHeader.vue';
import ChainPane from './ChainPane.vue';

export default {
  components: {
    ConsoleHeader,
    ChainPane,
  },
  computed: {
    activePathFn() {
      return this.$route.path;
    },
    passToken() {
      return Cookies.get('passToken', { path: '/', domain: '.yuhu.tech' });
    },
    userName() {
      return Cookies.get('userName', { path: '/', domain: '.yuhu.tech' });
      // return localStorage.getItem('userName');
    },
  },
  data() {
    return {
      tabPosition: 'left',
      tabRefreshed: false,
      isTabsVisible: true,
      chainMenu: {
        basic: [
          {
            index: '/chain/basic/browser',
            title: '浏览器服务',
            desc:
              '查看区块链系统的内部信息，如区块数、区块详细信息、交易数目、交易详细信息、链上统计数据等。',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/basic/browser.svg'),
            enabled: true,
          },
          {
            index: '/chain/basic/crosschain',
            title: '跨链服务',
            desc:
              '把区块链从分散的孤岛中拯救出来的良药，是区块链向外拓展和连接的桥梁。',
            iconType: 'img',
            // eslint-disable-next-line import/no-unresolved
            icon: require('@/assets/image/nav/chain/basic/crosschain.svg'),
            enabled: true,
          },
          /* {
            index: '/chain/basic/pluggable',
            title: '可插拔框架',
            desc:
              '这里应该是一段描述，但是因为暂时还没有描述，所以也就先这样了',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/basic/pluggable.svg'),
            enabled: false,
          }, */
          {
            index: '/chain/basic/contract',
            title: '智能合约和审计',
            desc:
              '对智能合约进行合适的审计，以确保不存在安全问题，并确保所有合约得到高效的执行。',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/basic/contract.svg'),
            enabled: true,
          },
          {
            index: '/chain/basic/aio',
            title: '一体机',
            desc:
              '深度融合软硬件技术、针对区块链技术特色打造的软硬一体化服务器。',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/basic/aio.svg'),
            enabled: true,
          },
          {
            index: '/chain/basic/kms',
            title: 'KMS服务',
            desc:
              '密钥管理服务（KMS）提供安全合规的密钥托管和密码服务，助您轻松使用密钥来加密保护敏感的数据资产。',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/basic/kms.svg'),
            enabled: false,
          },
        ],
        baas: [
          {
            index: '/chain/baas/ant',
            title: '蚂蚁BaaS服务',
            desc:
              '蚂蚁链自主研发的具备高性能、强隐私保护的区块链技术平台，帮助客户及合作伙伴快速轻松的搭建各类业务场景下的区块链应用，助力实体经济发展。',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/baas/ant.svg'),
            enabled: true,
          },
          {
            index: '/chain/baas/tencent',
            title: '腾讯TBaaS服务',
            desc:
              'TBaaS开放平台为腾讯区块链提供的企业级区块链应用开放平台，帮助客户从业务的角度理解区块链，专注于帮助企业快速搭建上层区块链应用场景。',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/baas/tencent.svg'),
            enabled: true,
          },
          {
            index: '/chain/baas/huawei',
            title: '华为BCS服务',
            desc:
              '区块链服务是面向企业及开发者的高性能、高可用和高安全的区块链技术平台服务，可以帮助企业和开发人员在华为云上快速、低成本的创建、部署和管理区块链应用。',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/baas/huawei.svg'),
            enabled: true,
          },
          {
            index: '/chain/baas/yiju',
            title: '易居E·BaaS',
            desc:
              '由易居（中国）控股有限公司旗下上海添玑网络服务有限公司自主研发。该平台旨在以不动产行业各细分业态为应用场景，利用区块链数据防篡改、信息加密、授权交互及追踪溯源的特性，帮助不动产企业及专业人士轻松构建业务联盟。',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/baas/yiju.svg'),
            enabled: true,
          },
        ],
        va: [
          {
            index: '/chain/va/evidence',
            title: '存证服务',
            desc:
              '通过区块链、哈希校验、电子签名、可信时间戳等技术保障电子数据法律效力，适用于多种应用场景，多种形式信息存证的一站式数据存证服务。',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/va/evidence.svg'),
            enabled: true,
          },
          {
            index: '/chain/va/privacy',
            title: '隐私计算',
            desc:
              '大规模多方安全计算商用平台，基于多方安全计算、隐私保护、区块链等技术，实现数据可用不可见，解决企业数据协同计算过程中的数据安全和隐私保护问题。',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/va/privacy.svg'),
            enabled: true,
          },
          {
            index: '/chain/va/oracle',
            title: '预言机服务',
            desc:
              '将外界信息写入到区块链内，完成区块链与现实世界的数据互通,是区块链与现实世界进行数据交互的接口。',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/va/oracle.svg'),
            enabled: true,
          },
          {
            index: '/chain/va/did',
            title: '数字身份',
            desc:
              '网络中的标识，其核心是通过提供和验证身份信息来证明“我是我”，其主要环节包括身份所有者注册身份、身份提供者签发身份、身份依赖者验证身份以及对身份信息和数据的管理。',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/va/did.svg'),
            enabled: true,
          },
          {
            index: '/chain/va/asset',
            title: '数字资产',
            desc:
              '企业或者个人拥有或控制的，并且以电子数据形式存在的，在日常活动之中持有以备出售或处于生产过程中的非货币性资产。',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/va/assets.svg'),
            enabled: true,
          },
          {
            index: '/chain/va/signature',
            title: '数字签章',
            desc:
              '专业的全生态电子签名服务。基于实名身份，为用户提供在线电子签章／签名服务的平台，并且为企业用户提供电子签名、电子合同管理、数据存证和法律维权等服务。',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/va/signature.svg'),
            enabled: true,
          },
          {
            index: '/chain/va/escrow',
            title: '私钥托管',
            desc:
              '通过权威第三方保存用户的私钥备份，在必要时帮助国家司法或安全等部门获取原始明文信息，也可在用户丢失、损坏自己的密钥的情况下恢复明文。',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/va/escrow.svg'),
            enabled: true,
          },
        ],
        alliance: [
          /* {
            index: '/chain/alliance/sourcetrace',
            title: '溯源链服',
            desc:
              '这里应该是一段描述，但是因为暂时还没有描述，所以也就先这样了',
            iconType: 'fas',
            icon: 'sliders-h',
            enabled: true,
          }, */
          {
            index: '/chain/alliance/justice',
            title: '司法链服',
            desc:
              '司法链是基于全球领先的区块链技术构建内外网一体的开放式司法链平台，全面提升法院司法能力、司法效能和司法公信力。',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/alliance/justice.svg'),
            enabled: true,
          },
          {
            index: '/chain/alliance/ssebs',
            title: '上证链服',
            desc:
              '上证链是由证所信息网络有限公司提供的云链一体化的基础技术设施，为行业的科技驱动业务创新提供基础支撑。',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/alliance/ssebs.svg'),
            enabled: true,
          },
          {
            index: '/chain/alliance/iec',
            title: '保交链服',
            desc:
              '保交链是由上海保险交易所主导的，是一条向联盟内部成员都开放的区块链。保交链帮助打通由内向外的保险行业，打通保险业上下游，使得交易数据可以得到共享，保险业的效率得到显著提高。',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/alliance/iec.svg'),
            enabled: true,
          },
          {
            index: '/chain/alliance/wtc',
            title: '梧桐链服',
            desc:
              '梧桐链是由同济大学牵头联合各领域领先企业共同发起研发、打造的国际领先、具有自主知识产权的联盟链底层技术平台。',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/alliance/wtc.svg'),
            enabled: true,
          },
          {
            index: '/chain/alliance/antsc',
            title: '蚂蚁溯源链服务',
            desc:
              '蚂蚁链大规模商用的溯源服务平台，拥有跨境商品、原产地真品、产业溯源、食品监管等溯源方案，适用于全领域溯源场景。',
            iconType: 'img',
            icon: require('@/assets/image/nav/chain/alliance/antsc.svg'),
            enabled: true,
          },
        ],
      },
    };
  },
  methods: {
    goBrowser() {
      window.open('https://console.yuhu.tech/browser');
      // window.open('https://explorer.yuhu.tech/');
    },
    goPaaS() {
      window.open('https://console.yuhu.tech');
    },
    signOut() {
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('userName');
      localStorage.removeItem('userPhone');
      Cookies.remove('passToken', { path: '/', domain: '.yuhu.tech' });
      window.location = 'https://www.yuhu.tech';
    },
    refresh(index, indexPath) {
      console.log('triggered', index, indexPath);
      if (!this.tabRefreshed) {
        this.isTabsVisible = false;
        this.tabRefreshed = true;
        this.$nextTick(() => {
          this.isTabsVisible = true;
        });
      }
    },
  },
};
</script>

<style scoped>
.chain {
  width: 700px;
  margin: 20px;
}

.el-tab-pane {
  margin: 20px;
}
.sticky {
  position: sticky;
  z-index: 1000;
  top: 0;
}

a:link,
a:visited {
  text-decoration: none; /*超链接无下划线*/
}

.tab-pane-item {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-block-start: 0px;
}
.row {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #e6e6e6;
}

.solutionBtn {
  padding: 15px;
}
.el-dropdown-link {
  cursor: pointer;
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
.userBtn {
  margin: 6px;
}
.consoleBtn {
  margin: 1vw 0;
}
</style>
