<template>
  <div>
    <div class="box-body-top">
      <el-input
        v-model="searchName"
        placeholder="用户名"
        style="width: 200px"
        suffix-icon="el-icon-search"
      ></el-input>
      <el-select
        v-model="authenticationType"
        placeholder="认证状态（全部）"
        style="margin-left: 20px"
      >
        <el-option label="实名认证" value="added"></el-option>
        <el-option label="未认证" value="joined"></el-option>
      </el-select>
    </div>
    <div>
      <el-table :data="accountTableData" border>
        <el-table-column prop="num" label="序号" width="100"> </el-table-column>
        <el-table-column prop="name" label="用户名" width="160">
        </el-table-column>
        <el-table-column prop="address" label="address"> </el-table-column>
        <el-table-column prop="phone" label="手机号" width="160">
        </el-table-column>
        <el-table-column prop="status" label="认证状态" width="160">
        </el-table-column>
        <el-table-column prop="configTableData" label="操作" width="180">
          <template v-slot="scope">
            <el-button @click="examine(scope.row)" type="text" size="small">
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog :visible.sync="dialogVisible1" width="45%" title="用户信息">
      <div>
        <div class="dialog">
          <el-row>
            <el-col :span="12">用户名：{{ dialogForm.row.name }}</el-col>
            <el-col :span="12">手机号：{{ dialogForm.row.phone }}</el-col>
          </el-row>
          <el-row> address：{{ dialogForm.row.address }} </el-row>
          <el-row> 认证状态：{{ dialogForm.row.status }} </el-row>
          <el-row> 已认证内容： </el-row>
          <el-checkbox-group v-model="dialogForm.checkedDetails">
            <el-checkbox
              v-for="value in dialogForm.authenticatedDetails"
              :key="value"
              :label="value"
              disabled
            ></el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchName: '',
      authenticationType: '',
      accountTableData: [
        {
          num: 1,
          name: 'sora',
          address: '2EE4f4B68be817515B7f64D74B625FA99a79a',
          phone: '159****0485',
          status: 'authenticated',
        },
        {
          num: 2,
          name: 'cat',
          address: '2EE4f4B68be817515B7f64D74B625FA99a79a',
          phone: '',
          status: 'unauthorized',
        },
      ],
      dialogVisible1: false,
      dialogForm: {
        row: {},
        checkedDetails: ['微信认证', '支付宝认证'],
        authenticatedDetails: [
          '微信认证',
          '支付宝认证',
          '银行卡认证',
          'CTID',
          '实名身份认证',
        ],
      },
    };
  },
  methods: {
    handleSelect(key) {
      this.active = key;
    },
    examine(row) {
      this.dialogForm.row = row;
      this.dialogVisible1 = true;
    },
  },
};
</script>

<style scoped>
.box {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  padding-left: 20px;
}

.box-title {
  width: 100%;
  height: 60px;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: PingFang SC Regular;
}

.box-displayboard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.box-body {
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.box-body-top {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.box-card {
  margin-top: 20px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.dialog {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.el-row {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
