<template>
  <div>
    <div class="box-body-top">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="我创建的服务" name="first"> </el-tab-pane>
        <el-tab-pane label="我参与的服务" name="second"></el-tab-pane>
      </el-tabs>
      <div class="box-body-top-right">
        <el-input
          v-model="searchName"
          placeholder="按数据以及名称搜索"
          style="width: 300px; margin-right: 20px"
          suffix-icon="el-icon-search"
        ></el-input>
        <el-button> 新建服务 </el-button>
      </div>
    </div>

    <div v-if="activeName === 'first'">
      <el-table :data="tableData" border>
        <el-table-column prop="name" label="服务名称" min-width="7">
        </el-table-column>
        <el-table-column prop="num" label="版本号" min-width="3">
        </el-table-column>
        <el-table-column prop="type" label="计算类型" min-width="4">
        </el-table-column>
        <el-table-column prop="source" label="数据源" min-width="4">
        </el-table-column>
        <el-table-column prop="date" label="修改日期" min-width="4">
        </el-table-column>
        <el-table-column prop="status" label="状态" min-width="4">
        </el-table-column>
        <el-table-column prop="opera1" label="操作" width="180">
          <template v-slot="scope">
            <div v-if="scope.row.status === 'authenticated'">
              <el-button
                @click="doSomething(scope.row)"
                type="text"
                size="small"
              >
                测试
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button
                @click="doSomething(scope.row)"
                type="text"
                size="small"
              >
                发布
              </el-button>
            </div>

            <div v-else>
              <el-button
                @click="doSomething(scope.row)"
                type="text"
                size="small"
              >
                部署审批
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button
                @click="doSomething(scope.row)"
                type="text"
                size="small"
              >
                编辑
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button
                @click="doSomething(scope.row)"
                type="text"
                size="small"
              >
                删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-else-if="activeName === 'second'">
      <el-table :data="tableData" border>
        <el-table-column prop="name" label="服务名称" min-width="7">
        </el-table-column>
        <el-table-column prop="num" label="版本号" min-width="3">
        </el-table-column>
        <el-table-column prop="type" label="计算类型" min-width="4">
        </el-table-column>
        <el-table-column prop="source" label="数据源" min-width="4">
        </el-table-column>
        <el-table-column prop="date" label="修改日期" min-width="4">
        </el-table-column>
        <el-table-column prop="status" label="状态" min-width="4">
        </el-table-column>
        <el-table-column prop="opera2" label="操作" width="180">
          <template v-slot="scope">
            <el-button @click="doSomething(scope.row)" type="text" size="small">
              审批
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchName: '',
      activeName: 'first',
      tableData: [
        {
          num: 1,
          name: 'sora',
          type: 'type',
          source: 'data',
          date: '2021-01-01',
          status: 'authenticated',
        },
        {
          num: 2,
          name: 'cat',
          type: 'type',
          source: 'data',
          date: '2021-01-01',
          status: 'unauthorized',
        },
      ],
    };
  },
  methods: {
    handleClick(tab, event) {
      // this.$nextTick(() => {
      //   this.activeName = tab.paneName;
      // });
      this.activeName = tab.paneName;
      console.log(tab, event, this.activeName);
    },
    doSomething(row) {
      console.log(row);
    },
  },
};
</script>

<style scoped>
.box-body-top {
  display: flex;
  justify-content: space-between;
}
.box-body-top-right {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
</style>
