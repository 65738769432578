<script>
import PassConfirm from './account/PassConfirm.vue';
import ConsoleHeader from './ConsoleHeader.vue';
import AccountNavMenu from './account/NavMenu.vue';

// BaaS
import BaaSDisplayBoard from '../chain/baas/DisplayBoard.vue';
import BaaSNavMenu from '../chain/baas/NavMenu.vue';
import BaaSManagement from '../chain/baas/Management.vue';
import BaaSApplication from '../chain/baas/application/Index.vue';

// BaaS Governance
import BaaSGovernanceMenu from '../chain/baas/governance/Menu.vue';
import BaaSGovernanceNode from '../chain/baas/governance/node/Index.vue';
import BaaSGovernanceConfig from '../chain/baas/governance/Config.vue';
import BaaSGovernanceAccount from '../chain/baas/governance/account/Index.vue';

// SourceTrace
import SourceTraceNavMenu from '../chain/alliance/sourcetrace/NavMenu.vue';
import SourceTraceMerchant from '../chain/alliance/sourcetrace/Merchant.vue';
import SourceTraceCommodity from '../chain/alliance/sourcetrace/Commodity.vue';
import SourceTraceQrcode from '../chain/alliance/sourcetrace/Qrcode.vue';
import SourceTraceBatch from '../chain/alliance/sourcetrace/Batch.vue';
import SourceTraceConfig from '../chain/alliance/sourcetrace/Config.vue';
import SourceTraceAccount from '../chain/alliance/sourcetrace/Account.vue';

// Privacy
import PrivacyNavMenu from '../chain/va/privacy/NavMenu.vue';
import PrivacyInfo from '../chain/va/privacy/Info.vue';
import PrivacyProject from '../chain/va/privacy/Project.vue';
import PrivacyData from '../chain/va/privacy/Data.vue';
import PrivacyMonitor from '../chain/va/privacy/Monitor.vue';
import PrivacyDataModel from '../chain/va/privacy/data/Model.vue';
import PrivacyDataSecurity from '../chain/va/privacy/data/Security.vue';
import PrivacyDataManagement from '../chain/va/privacy/data/Management.vue';

// DiD
import DIDNavMenu from '../chain/va/did/NavMenu.vue';
import DIDAccount from '../chain/va/did/Account.vue';
import DIDScan from '../chain/va/did/Scan.vue';
import DIDConfig from '../chain/va/did/Config.vue';

export {
  PassConfirm,
  ConsoleHeader,
  AccountNavMenu,
  BaaSDisplayBoard,
  BaaSNavMenu,
  BaaSManagement,
  BaaSApplication,
  BaaSGovernanceMenu,
  BaaSGovernanceNode,
  BaaSGovernanceConfig,
  BaaSGovernanceAccount,
  SourceTraceNavMenu,
  SourceTraceMerchant,
  SourceTraceCommodity,
  SourceTraceQrcode,
  SourceTraceBatch,
  SourceTraceConfig,
  SourceTraceAccount,
  PrivacyNavMenu,
  PrivacyInfo,
  PrivacyProject,
  PrivacyData,
  PrivacyMonitor,
  PrivacyDataModel,
  PrivacyDataSecurity,
  PrivacyDataManagement,
  DIDNavMenu,
  DIDAccount,
  DIDScan,
  DIDConfig,
};

export default {};
</script>
