<template>
  <el-container>
    <el-row style="margin-bottom: 20px; display: flex; align-item: center">
      <el-col :span="24">
        <el-menu
          width="100%"
          :default-active="activeIndex"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1"> 本机内应用 </el-menu-item>
          <el-menu-item index="2"> 联盟内共享应用 </el-menu-item>
          <el-menu-item index="3"> 平台应用 </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
    <div class="node-body">
      <Native v-if="this.active == 1" />
      <Alliance v-if="this.active == 2" />
      <Platform v-else-if="this.active == 3" />
      <!-- <div v-else></div> -->
    </div>
  </el-container>
</template>

<script>
// import { Button } from '@/components/common/Index';
import Alliance from './Alliance.vue';
import Native from './Native.vue';
import Platform from './Platform.vue';

export default {
  data() {
    return {
      active: 1,
      activeIndex: '1',
    };
  },
  components: {
    // Button,
    Alliance,
    Native,
    Platform,
  },
  methods: {
    handleSelect(key) {
      this.active = key;
    },
  },
};
</script>

<style scoped>
.node-body {
  /* display: flex */
  height: 100%;
  /* flex: 1; */
}
</style>
