<template>
  <div>
    <el-table :data="accountManagement" border>
      <el-table-column prop="orgName" label="机构名称" min-width="30%">
      </el-table-column>
      <el-table-column prop="authorizedNode" label="已授权节点" min-width="70%">
      </el-table-column>
      <el-table-column prop="authorizedNode" label="操作" width="300">
        <template v-slot="scope">
          <Button
            @click="fix(scope.row)"
            type="mini-blue"
            style="margin-right: 20px"
          >
            修改
          </Button>
          <Button type="mini-red" @click="remove(scope.row)">删除</Button>
        </template>
      </el-table-column>
    </el-table>

    <Dialog
      title="修改"
      :visible.sync="dialogVisible"
      @dialogClose="dialogVisible = false"
    >
      <el-form
        ref="form"
        :model="fixOrgDialog"
        label-width="auto"
        label-position="left"
      >
        <el-form-item label="选择机构">
          <el-select
            v-model="fixOrgDialog.selectOrg"
            placeholder="选择机构"
            class="form-select"
          >
            <el-option
              v-for="item in fixOrgDialog.list"
              :key="item.nodeId"
              :label="item.orgName"
              :value="item.nodeId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择节点">
          <el-input
            v-model="fixOrgDialog.searchNode"
            placeholder="节点名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="margin-top: 15px">
        <el-checkbox-group
          v-model="fixOrgDialog.selectNode"
          style="text-align: left"
        >
          <el-checkbox
            v-for="value in nodeList"
            :key="value.id"
            :label="value.title"
          ></el-checkbox>
        </el-checkbox-group>
      </div>
      <span style="display: flex; justify-content: center; margin-top: 20px">
        <Button @click="dialogVisible = false" style="margin-right: 20px">
          取 消
        </Button>
        <Button type="primary" @click="dialogVisible = false"> 保 存 </Button>
      </span>
    </Dialog>
  </div>
</template>

<script>
import { Button, Dialog } from '@/components/common/Index';

export default {
  components: {
    Button,
    Dialog,
  },
  data() {
    return {
      accountManagement: [
        {
          orgName: '机构名称',
          authorizedNode: 'authorizedNode已授权节点',
        },
      ],
      dialogVisible: false,
      nodeList: [
        {
          id: 'node1',
          title: '云节点1',
        },
        {
          id: 'node2',
          title: '云节点2',
        },
        {
          id: 'node3',
          title: '云节点3',
        },
        {
          id: 'node4',
          title: '云节点4',
        },
        {
          id: 'node5',
          title: '云节点5',
        },
        {
          id: 'node6',
          title: '云节点6',
        },
        {
          id: 'node7',
          title: '云节点7',
        },
      ],
      fixOrgDialog: {
        selectOrg: '',
        list: [
          {
            nodeId: 'node1',
            orgName: 'orgName申请机构名称',
          },
          {
            nodeId: 'node2',
            orgName: 'node2',
          },
        ],
        selectNode: [],
        searchNode: '',
      },
    };
  },
  methods: {
    fix(row) {
      console.log(row);
      this.dialogVisible = true;
    },
    remove(row) {
      console.log(row);
    },
  },
};
</script>

<style scoped>
.dialog-fixorg {
  display: flex;
  flex-direction: column;
}
.dialog-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
}
.input {
  width: 40%;
}
.form-box {
  text-align: start;
}
.form-select {
  width: 100%;
}
</style>
