<template>
  <div class="box1">
    <div class="box-top-button">
      <!-- <el-button @click="buy" type="text" class="top-button">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div style="display: flex; align-items: center; margin-right: 5px">
            <inline-svg
              :src="require('@/assets/image/nav/chain/basic/browser.svg')"
              :fill="'#599ef8'"
              style="height: 20px; width: 20px"
            />
          </div>
          <span> 新增购买 </span>
        </div>
      </el-button> -->
      <Button
        :src="require('@/assets/image/chain/baas/management/update.png')"
        :fill="'#599ef8'"
        text="新增购买"
        type="blue"
        @click="buy"
      />
    </div>
    <el-card
      class="child-card"
      shadow="hover"
      v-for="(item, index) in BaaSList"
      :key="index"
      :body-style="{ padding: '0px 20px 20px 20px' }"
    >
      <div class="card-top">
        <el-col :span="14">
          <a>{{ item.name }}</a>
          <el-button
            type="text"
            @click="dialogVisible2 = true"
            style="margin-left: 10px"
          >
            <img
              :src="require('@/assets/image/chain/baas/management/modify.png')"
            />
          </el-button>

          <el-dialog
            :visible.sync="dialogVisible2"
            width="414px"
            title="修改链名称"
          >
            <div>
              <div class="dialog2-line1-text">联盟链id：{{ item.id }}</div>
              <div class="dialog2-input">
                <div class="dialog2-text">*联盟链名称：</div>
                <el-input
                  v-model="dialogVisible2Value.name"
                  placeholder="联盟链名称"
                  class="input"
                ></el-input>
              </div>
              <span slot="footer" class="dialog-footer">
                <Button @click="dialogVisible2 = false" text="保 存" />
              </span>
            </div>
          </el-dialog>
        </el-col>
        <el-col :offset="1" style="display: flex">
          <div class="card-top-network">
            <el-tag v-if="item.network" type="success"> 网络正常 </el-tag>
            <el-tag v-else type="danger"> 网络异常 </el-tag>
          </div>

          <Button
            v-if="item.type == 0"
            :src="require('@/assets/image/chain/baas/management/update.png')"
            :fill="'#599ef8'"
            text="基础版升级"
            type="png"
          />
          <Button
            v-else-if="item.type == 1"
            :src="require('@/assets/image/chain/baas/management/update.png')"
            :fill="'#599ef8'"
            text="高级版"
            type="png"
          />
        </el-col>
        <div style="display: flex">
          <Button
            :src="require('@/assets/image/chain/baas/management/browser.png')"
            :fill="'#599ef8'"
            text="浏览器"
            style="margin-right: 20px"
            type="png"
          />

          <Button
            :src="require('@/assets/image/chain/baas/management/tool.png')"
            :fill="'#599ef8'"
            text="开发者工具"
            style="margin-right: 20px"
            type="png"
          />

          <Button
            :src="
              require('@/assets/image/chain/baas/management/certificate.png')
            "
            :fill="'#599ef8'"
            text="证书"
            style="margin-right: 20px"
            type="png"
          />

          <Button
            :src="require('@/assets/image/chain/baas/management/browser.png')"
            :fill="'#599ef8'"
            text="管理"
            @click="governance(item.id)"
            type="png"
          />

          <el-dialog :visible.sync="dialogVisible1" width="30%">
            <div class="dialog1">
              <div class="dialog-button">
                <el-button @click="auto" class="button">
                  <el-radio>自动生成密钥和证书</el-radio>
                  <div class="text-wrapper">{{ text }}</div>
                </el-button>
              </div>
              <div class="dialog-button">
                <el-button @click="manual" class="button">
                  <el-radio>手动生成密钥和证书</el-radio>
                  <div class="text-wrapper">{{ text }}</div>
                </el-button>
              </div>
            </div>
          </el-dialog>
        </div>
      </div>

      <div class="card-bottom">
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
          "
        >
          <div style="display: flex; align-items: center; margin-right: 20px">
            <inline-svg
              :src="require('@/assets/image/nav/chain/basic/browser.svg')"
              :fill="'#599ef8'"
              class="svg"
            />
          </div>
          <div class="card-bottom-item">
            <div>节点数（个）</div>
            <div class="number">{{ item.count }}</div>
          </div>
          <div style="display: flex; align-items: center; margin-right: 20px">
            <inline-svg
              :src="require('@/assets/image/nav/chain/basic/browser.svg')"
              :fill="'#599ef8'"
              class="svg"
            />
          </div>
          <div class="card-bottom-item">
            <div>区块高度</div>
            <div class="number">{{ item.blockheight }}</div>
          </div>
          <div style="display: flex; align-items: center; margin-right: 20px">
            <inline-svg
              :src="require('@/assets/image/nav/chain/basic/browser.svg')"
              :fill="'#599ef8'"
              class="svg"
            />
          </div>
          <div class="card-bottom-item">
            <div>总交易量</div>
            <div class="number">{{ item.txAmount }}</div>
          </div>
        </div>
        <div class="card-bottom-version">版本号：{{ item.version }}</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { Button } from '@/components/common/Index';

export default {
  name: 'BaaSManagement',
  components: {
    Button,
  },
  data() {
    return {
      BaaSList: [
        {
          id: 'id1', // id用于跳转到该链的管理页面
          name: '合约链ididid',
          count: 4,
          blockheight: 267,
          txAmount: 26703345,
          network: true, // 网络正常
          version: 'V0.10.2.12.10',
          type: 0, // 0:基础版，1:升级版
        },
        {
          id: 'id2',
          name: '合约链ididid',
          count: 4,
          blockheight: 267,
          txAmount: 26703345,
          network: false, // 网络异常
          version: 'V0.10.2.12.10',
          type: 1,
        },
      ],
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible2Value: {
        id: '',
        name: '',
      },
      text:
        '特性描述特性描述特性描述特性描述特性描述特性描述特性描述特性描述特性描述特性描述特性描述特性描述特性描述特性描述',
    };
  },
  methods: {
    buy() {
      // 新增购买
      this.$router
        .push({
          name: 'Form',
          query: {
            id: this.$route.matched.slice(-1)[0].props.default.formId,
            groupName: this.$route.matched.slice(-1)[0].props.default.title,
          },
        })
        .catch((e) => e);
    },
    governance(id) {
      // 跳转去指定联盟链的管理页面
      this.$router
        .push({ name: '节点管理', params: { chainId: id } })
        .catch((e) => e);
      // this.$router.push(`/console/baas/governance?id=${id}`).catch((e) => e);
    },
    auto() {
      this.dialogVisible1 = false;
      this.dialogVisible2 = true;
    },
    manual() {
      this.dialogVisible1 = false;
      this.dialogVisible3 = true;
    },
  },
};
</script>

<style scoped>
.box1 {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: static !important;
}
.box-top-button {
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  /* width: 100%; */
  position: absolute;
  top: 6px;
  right: 25px;
}
.child-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 175px;
  margin-bottom: 20px;
  font-size: 16px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-align: left;
  color: #3d4966;
}
.card-top {
  border-bottom: solid 1px #eee;
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
}
.card-top-network {
  margin-right: 30px;
}
.card-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 111px;
}
.card-bottom-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
  width: 18%;
}
.card-bottom-version {
  display: flex;
  width: 40%;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 20px;
}
.dialog1 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 300px;
}
.dialog-button {
  height: 50%;
  width: 100%;
}
.button {
  display: flex;
  flex: 1;
  height: 80%;
  width: 90%;
  justify-content: center;
  align-items: center;
}
.text-wrapper {
  white-space: pre-wrap; /*文本换行*/
}
.dialog2-input {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-around;
  text-align: left;
}
.dialog2-text {
  width: 140px;
  text-align: left;
  display: flex;
  align-items: center;
}
.dialog2-line1-text {
  width: 140px;
  text-align: left;
  margin-bottom: 15px;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.svg {
  height: 70px;
  width: 70px;
}

.top-button {
  font-size: 14px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #3377ff;
  line-height: 14px;
}
.number {
  width: 22px;
  height: 30px;
  font-size: 40px;
  font-family: PingFang SC Bold, PingFang SC Bold-Bold;
  font-weight: 700;
  text-align: left;
  color: #3377ff;
  line-height: 36px;
}
</style>
