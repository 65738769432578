<template>
  <v-echart :options="pie" />
</template>

<style>
/**
 * The default size is 600px×400px, for responsive charts
 * you may need to set percentage values as follows (also
 * don't forget to provide a size for the container).
 */
.echarts {
  width: 100%;
  height: 100%;
}
</style>

<script>
import 'echarts/lib/chart/pie';

export default {
  data() {
    return {
      pie: {
        title: {
          text: '磁盘占用',
          x: 'center',
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          left: 10,
          data: ['未使用量', '已使用量'],
        },
        series: [
          {
            name: '磁盘占用',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: true,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '30',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 2.1, name: '已使用量' },
              { value: 497.9, name: '未使用量' },
            ],
            itemStyle: {
              normal: {
                color(params) {
                  const colorList = [
                    '#65d186',
                    '#f67287',
                    '#f29e3c',
                    '#c05bdd',
                    '#f29e3c',
                    '#7a65f2',
                    '#65d186',
                    '#f67287',
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      },
    };
  },
};
</script>
