<template>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage4"
    :page-size="20"
    layout="total, sizes, prev, pager, next, jumper"
    :total="1000"
  >
  </el-pagination>
</template>

<script>
export default {
  props: ['src', 'fill', 'text'],
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
  data() {
    return {
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 1,
    };
  },
};
</script>

<style scoped>
.svg {
  height: 20px;
  width: 20px;
}

.el-button {
  /* width: 120px;
  height: 38px; */
  background: #ffffff;
  border-radius: 4px;
  border-color: transparent;
  font-size: 16px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #3d4966;
  padding: 0;
  line-height: normal;
}
</style>
