import Vue from 'vue';
import VueRouter from 'vue-router';
import Cookies from 'js-cookie';

// Main Entrance
// import Index from '../views/Index.vue';

// import Feed from '../views/Feed.vue';
// import Chain from '../views/Chain.vue';
// import AppStore from '../views/AppStore.vue';
// import Audition from '../views/Audition.vue';
// import Support from '../views/Support.vue';
// import Developer from '../views/Developer.vue';
// import About from '../views/About.vue';
// import Console from '../views/Console.vue';

// // Support
// import SelfAssist from '../views/support/SelfService.vue';
// import FAQ from '../views/support/FAQ.vue';
// import Discuss from '../views/support/Discuss.vue';
// import Bulletin from '../views/support/Bulletin.vue';
//
// // Chain - Alliance
// import IEC from '../views/chain/alliance/IEC.vue';
// import Justice from '../views/chain/alliance/Justice.vue';
// import SSEBS from '../views/chain/alliance/SSEBS.vue';
// import WTC from '../views/chain/alliance/Wtc.vue';
// import Antsc from '../views/chain/alliance/antsc.vue';
//
// // Chain - BaaS
// // import BaaSLeaflet from '../views/chain/baas/Index.vue';
// import AntLeaflet from '../views/chain/baas/Ant.vue';
// import TencentLeaflet from '../views/chain/baas/Tencent.vue';
// import HuaweiLeaflet from '../views/chain/baas/Huawei.vue';
//
// // Chain - Basic
// import AIO from '../views/chain/basic/AIO.vue';
// import Browser from '../views/chain/basic/Browser.vue';
// import Contract from '../views/chain/basic/Contract.vue';
// import CrossChain from '../views/chain/basic/CrossChain.vue';
//
// // Chain - CA
// import EBDetail from '../views/chain/ca/api/EBDetail.vue';
// import EBInfo from '../views/chain/ca/api/EBInfo.vue';
// import InPersonCert from '../views/chain/ca/api/InPersonCert.vue';
// import RNICert from '../views/chain/ca/api/RNICert.vue';
// import RNITimestamp from '../views/chain/ca/api/RNITimestamp.vue';
// import RNPhoneVeri from '../views/chain/ca/api/RNPhoneVeri.vue';
// import StaticId from '../views/chain/ca/api/StaticId.vue';
// import VideoId from '../views/chain/ca/api/VideoId.vue';
// import CertWidget from '../views/chain/ca/verification/CertWidget.vue';
// import DigiCert from '../views/chain/ca/verification/DigiCert.vue';
//
// // Chain - Value-Add Service
// import Adapter from '../views/chain/va/Adapter.vue';
// import Asset from '../views/chain/va/Asset.vue';
// import DID from '../views/chain/va/DID.vue';
// import Escrow from '../views/chain/va/Escrow.vue';
// import Evidence from '../views/chain/va/Evidence.vue';
// import Oracle from '../views/chain/va/Oracle.vue';
// import Privacy from '../views/chain/va/Privacy.vue';
// import Signature from '../views/chain/va/Signature.vue';
//
// // Chain - Cloud
// import Aliyun from '../views/chain/cloud/Aliyun.vue';
// import Huawei from '../views/chain/cloud/Huawei.vue';
// import Tencent from '../views/chain/cloud/Tencent.vue';
//
// // Feed
// // import Trophy from '../views/feed/trophy/List.vue';
// // import TrophyDetail from '../views/feed/trophy/Detail.vue';
// // import Campaign from '../views/feed/campaign/List.vue';
// // import CampaignDetail from '../views/feed/campaign/Detail.vue';
// // import Field from '../views/feed/field/List.vue';
// // import FieldDetail from '../views/feed/field/Detail.vue';
//
// // Console
// import Account from '../views/console/Login.vue';
// import Management from '../views/console/Management.vue';
// import AccountInfo from '../views/console/accountinfo/AccountInfo.vue';
// import EnterpriseCertification from '../views/console/accountinfo/EnterpriseCertification.vue';
// import Admission from '../views/console/admission/Admission.vue';
// import AdmissionList from '../views/console/admission/AdmissionList.vue';
// import ConsoleDisplay from '../views/console/Display.vue';
// import ConsoleBreadcrumb from '../views/console/Breadcrumb.vue';
//
// // Console BaaS
// import BaaSDisplayBoard from '../views/console/baas/DisplayBoard.vue';
// import BaaSManagement from '../views/console/baas/Management.vue';
// import BaaSGovernance from '../views/console/baas/governance/Governance.vue';
// import BaaSGovernanceNode from '../views/console/baas/governance/Node.vue';
// import BaaSGovernanceConfig from '../views/console/baas/governance/Config.vue';
// import BaaSGovernanceContract from '../views/console/baas/governance/Contract.vue';
// import BaaSGovernanceAccount from '../views/console/baas/governance/Account.vue';
// import BaaSGovernanceApplication from '../views/console/baas/governance/Application.vue';
// import BaaSGovernanceCrosschain from '../views/console/baas/governance/Crosschain.vue';
// import BaaSGovernanceOracle from '../views/console/baas/governance/Oracle.vue';
// import BaaSCertificate from '../views/console/baas/Certificate.vue';
// import BaaSApplication from '../views/console/baas/Application.vue';
// import BaaSUnionManage from '../views/console/baas/UnionManage.vue';
//
// // Console SourceTrace
// import SourceTraceMerchant from '../views/console/sourcetrace/Merchant.vue';
// import SourceTraceCommodity from '../views/console/sourcetrace/Commodity.vue';
// import SourceTraceQrcode from '../views/console/sourcetrace/Qrcode.vue';
// import SourceTraceBatch from '../views/console/sourcetrace/Batch.vue';
// import SourceTraceConfig from '../views/console/sourcetrace/Config.vue';
// import SourceTraceAccount from '../views/console/sourcetrace/Account.vue';
//
// // Console Privacy
// import PrivacyInfo from '../views/console/privacy/Info.vue';
// import PrivacyProject from '../views/console/privacy/Project.vue';
// // import PrivacyData from '../views/console/privacy/Data.vue';
// import PrivacyMonitor from '../views/console/privacy/Monitor.vue';
// import PrivacyDataModel from '../views/console/privacy/data/Model.vue';
// import PrivacyDataSecurity from '../views/console/privacy/data/Security.vue';
// import PrivacyDataManagement from '../views/console/privacy/data/Management.vue';
// import PrivacyDataConfigmodel from '../views/console/privacy/data/Configmodel.vue';
//
// // Console DID
// import DIDAccount from '../views/console/did/Account.vue';
// import DIDConfig from '../views/console/did/Config.vue';
//
// // Solution
// import Digital from '../views/solution/Digital.vue';
// import Farming from '../views/solution/Farming.vue';
// import Landed from '../views/solution/Landed.vue';
// import Human from '../views/solution/Human.vue';
// import SupplyChain from '../views/solution/SupplyChain.vue';
// import Financial from '../views/solution/Financial.vue';
// import Internet from '../views/solution/Internet.vue';
//
// // Form
// import Form from '../views/SubmitForm.vue';
//
// // Others
// import Thanks from '../views/common/Thanks.vue';

const Index = () => import('../views/Index.vue');
const Chain = () => import('../views/Chain.vue');
const AppStore = () => import('../views/AppStore.vue');
const Audition = () => import('../views/Audition.vue');
const Support = () => import('../views/Support.vue');
const Developer = () => import('../views/Developer.vue');
const About = () => import('../views/About.vue');
const Console = () => import('../views/Console.vue');
const SelfAssist = () => import('../views/support/SelfService.vue');
const FAQ = () => import('../views/support/FAQ.vue');
const Discuss = () => import('../views/support/Discuss.vue');
const Bulletin = () => import('../views/support/Bulletin.vue');
const IEC = () => import('../views/chain/alliance/IEC.vue');
const Justice = () => import('../views/chain/alliance/Justice.vue');
const SSEBS = () => import('../views/chain/alliance/SSEBS.vue');
const WTC = () => import('../views/chain/alliance/Wtc.vue');
const Antsc = () => import('../views/chain/alliance/antsc.vue');
const AntLeaflet = () => import('../views/chain/baas/Ant.vue');
const TencentLeaflet = () => import('../views/chain/baas/Tencent.vue');
const HuaweiLeaflet = () => import('../views/chain/baas/Huawei.vue');
const YijuLeaflet = () => import('../views/chain/baas/Yiju.vue');
const AIO = () => import('../views/chain/basic/AIO.vue');
const Browser = () => import('../views/chain/basic/Browser.vue');
const Contract = () => import('../views/chain/basic/Contract.vue');
const CrossChain = () => import('../views/chain/basic/CrossChain.vue');
const EBDetail = () => import('../views/chain/ca/api/EBDetail.vue');
const EBInfo = () => import('../views/chain/ca/api/EBInfo.vue');
const InPersonCert = () => import('../views/chain/ca/api/InPersonCert.vue');
const RNICert = () => import('../views/chain/ca/api/RNICert.vue');
const RNITimestamp = () => import('../views/chain/ca/api/RNITimestamp.vue');
const RNPhoneVeri = () => import('../views/chain/ca/api/RNPhoneVeri.vue');
const StaticId = () => import('../views/chain/ca/api/StaticId.vue');
const VideoId = () => import('../views/chain/ca/api/VideoId.vue');
const CertWidget = () => import('../views/chain/ca/verification/CertWidget.vue');
const DigiCert = () => import('../views/chain/ca/verification/DigiCert.vue');
const Adapter = () => import('../views/chain/va/Adapter.vue');
const Asset = () => import('../views/chain/va/Asset.vue');
const DID = () => import('../views/chain/va/DID.vue');
const Escrow = () => import('../views/chain/va/Escrow.vue');
const Evidence = () => import('../views/chain/va/Evidence.vue');
const Oracle = () => import('../views/chain/va/Oracle.vue');
const Privacy = () => import('../views/chain/va/Privacy.vue');
const Signature = () => import('../views/chain/va/Signature.vue');
const Aliyun = () => import('../views/chain/cloud/Aliyun.vue');
const Huawei = () => import('../views/chain/cloud/Huawei.vue');
const Tencent = () => import('../views/chain/cloud/Tencent.vue');
const Account = () => import('../views/console/Login.vue');
const Management = () => import('../views/console/Management.vue');
const AccountInfo = () => import('../views/console/accountinfo/AccountInfo.vue');
const EnterpriseCertification = () => import('../views/console/accountinfo/EnterpriseCertification.vue');
const Admission = () => import('../views/console/admission/Admission.vue');
const AdmissionList = () => import('../views/console/admission/AdmissionList.vue');
const ConsoleDisplay = () => import('../views/console/Display.vue');
const ConsoleBreadcrumb = () => import('../views/console/Breadcrumb.vue');
const BaaSDisplayBoard = () => import('../views/console/baas/DisplayBoard.vue');
const BaaSManagement = () => import('../views/console/baas/Management.vue');
const BaaSGovernance = () => import('../views/console/baas/governance/Governance.vue');
const BaaSGovernanceNode = () => import('../views/console/baas/governance/Node.vue');
const BaaSGovernanceConfig = () => import('../views/console/baas/governance/Config.vue');
const BaaSGovernanceContract = () => import('../views/console/baas/governance/Contract.vue');
const BaaSGovernanceAccount = () => import('../views/console/baas/governance/Account.vue');
const BaaSGovernanceApplication = () => import('../views/console/baas/governance/Application.vue');
const BaaSGovernanceCrosschain = () => import('../views/console/baas/governance/Crosschain.vue');
const BaaSGovernanceOracle = () => import('../views/console/baas/governance/Oracle.vue');
const BaaSCertificate = () => import('../views/console/baas/Certificate.vue');
const BaaSApplication = () => import('../views/console/baas/Application.vue');
const BaaSUnionManage = () => import('../views/console/baas/UnionManage.vue');
const SourceTraceMerchant = () => import('../views/console/sourcetrace/Merchant.vue');
const SourceTraceCommodity = () => import('../views/console/sourcetrace/Commodity.vue');
const SourceTraceQrcode = () => import('../views/console/sourcetrace/Qrcode.vue');
const SourceTraceBatch = () => import('../views/console/sourcetrace/Batch.vue');
const SourceTraceConfig = () => import('../views/console/sourcetrace/Config.vue');
const SourceTraceAccount = () => import('../views/console/sourcetrace/Account.vue');
// const PrivacyInfo = () => import('../views/console/privacy/Info.vue');
const PrivacyProject = () => import('../views/console/privacy/Project.vue');
const PrivacyMonitor = () => import('../views/console/privacy/Monitor.vue');
const PrivacyDataModel = () => import('../views/console/privacy/data/Model.vue');
const PrivacyDataSecurity = () => import('../views/console/privacy/data/Security.vue');
const PrivacyDataManagement = () => import('../views/console/privacy/data/Management.vue');
const PrivacyDataConfigmodel = () => import('../views/console/privacy/data/Configmodel.vue');
const DIDAccount = () => import('../views/console/did/Account.vue');
const DIDConfig = () => import('../views/console/did/Config.vue');
const Digital = () => import('../views/solution/Digital.vue');
const Farming = () => import('../views/solution/Farming.vue');
const Landed = () => import('../views/solution/Landed.vue');
const Human = () => import('../views/solution/Human.vue');
const SupplyChain = () => import('../views/solution/SupplyChain.vue');
const Financial = () => import('../views/solution/Financial.vue');
const Internet = () => import('../views/solution/Internet.vue');
const Solution1 = () => import('../views/solution/Solution1.vue');
const Form = () => import('../views/SubmitForm.vue');
const Thanks = () => import('../views/common/Thanks.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    props: {
      label: '首页',
    },
    meta: {
      title: '区块链服务平台 - 域乎 - 创造可信数字世界',
      show: false,
      content: {
        keywords: '域乎官网,域乎官方网站,苏州域乎区块链科技有限公司,上海域乎信息技术有限公司,域乎,域乎科技,区块链服务,链服平台,区块链技术服务,'
          + '数据服务, 数字资产服务, 数字资产发布, 区块链开发, 区块链接入, 可信数据资产服务, 数据资产服务, 区块链定制',
        description: '域乎是一家专注于区块链技术的高新技术企业，公司专注于区块链基础设施与数字资产服务业务，致力于成为价值互联网的创新者。',
      },
    },
    component: Index,
  },
  // {
  //   path: '/feed',
  //   name: 'Feed',
  //   props: {
  //     label: '政策资讯',
  //   },
  //   // eslint-disable-next-line import/no-dynamic-require,arrow-parens
  //   component: Feed,
  // },
  // {
  //   path: '/feed/trophy',
  //   name: 'Trophy',
  //   props: {
  //     label: '成果展示',
  //   },
  //   component: Trophy,
  // },
  // {
  //   path: '/feed/trophy/:id',
  //   name: 'TrophyDetail',
  //   props: {
  //     label: '成果详情',
  //   },
  //   component: TrophyDetail,
  // },
  // {
  //   path: '/feed/campaign',
  //   name: 'Campaign',
  //   props: {
  //     label: '活动宣传',
  //   },
  //   component: Campaign,
  // },
  // {
  //   path: '/feed/campaign/:id',
  //   name: 'CampaignDetail',
  //   props: {
  //     label: '活动宣传详情',
  //   },
  //   component: CampaignDetail,
  // },
  // {
  //   path: '/feed/field',
  //   name: 'Field',
  //   props: {
  //     label: '行业资讯',
  //   },
  //   component: Field,
  // },
  // {
  //   path: '/feed/field/:id',
  //   name: 'FieldDetail',
  //   props: {
  //     label: '行业资讯详情',
  //   },
  //   component: FieldDetail,
  // },
  /* {
    path: '/chain/baas',
    name: 'BaaSLeaflet',
    props: {
      label: '域乎BaaS',
    },
    component: BaaSLeaflet,
  }, */
  {
    path: '/chain/baas/ant',
    name: 'Ant',
    props: {
      formId: 42,
      label: '蚂蚁BaaS服务',
    },
    component: AntLeaflet,
  },
  {
    path: '/chain/baas/tencent',
    name: 'Tencent',
    props: {
      formId: 43,
      label: '腾讯TBaaS服务',
    },
    component: TencentLeaflet,
  },
  {
    path: '/chain/baas/huawei',
    name: 'Huawei',
    props: {
      formId: 44,
      label: '华为BCS服务',
    },
    component: HuaweiLeaflet,
  },
  {
    path: '/chain/baas/yiju',
    name: 'Yiju',
    props: {
      formId: 68,
      label: '易居E·BaaS',
    },
    component: YijuLeaflet,
  },
  {
    path: '/chain/basic/browser',
    name: 'BrowserService',
    props: {
      formId: 38,
      label: '浏览器服务',
    },
    component: Browser,
  },
  {
    path: '/chain/basic/crosschain',
    name: 'CrossChain',
    props: {
      formId: 39,
      label: '跨链服务',
    },
    component: CrossChain,
  },
  {
    path: '/chain/basic/contract',
    name: 'Contract',
    props: {
      formId: 40,
      label: '智能合约和审计',
    },
    component: Contract,
  },
  {
    path: '/chain/basic/aio',
    name: 'AIO',
    props: {
      formId: 41,
      label: '一体机',
    },
    component: AIO,
  },
  {
    path: '/chain/va/evidence',
    name: 'Evidence',
    props: {
      formId: 45,
      label: '存证服务',
    },
    component: Evidence,
  },
  {
    path: '/chain/va/privacy',
    name: 'Privacy',
    props: {
      formId: 46,
      label: '隐私计算',
    },
    component: Privacy,
  },
  {
    path: '/chain/va/oracle',
    name: 'Oracle',
    props: {
      formId: 47,
      label: '预言机服务',
    },
    component: Oracle,
  },
  {
    path: '/chain/va/did',
    name: 'DID',
    props: {
      formId: 48,
      label: '数字身份',
    },
    component: DID,
  },
  {
    path: '/chain/va/asset',
    name: 'Asset',
    props: {
      formId: 49,
      label: '数字资产',
    },
    component: Asset,
  },
  {
    path: '/chain/va/adapter',
    name: 'Adapter',
    props: {
      label: '适配器',
    },
    component: Adapter,
  },
  {
    path: '/chain/va/signature',
    name: 'Signature',
    props: {
      formId: 50,
      label: '数字签章',
    },
    component: Signature,
  },
  {
    path: '/chain/va/escrow',
    name: 'Escrow',
    props: {
      formId: 51,
      label: '私钥托管',
    },
    component: Escrow,
  },
  {
    path: '/developer',
    name: 'Developer',
    props: {
      label: '开发者服务',
    },
    components: Developer,
  },
  {
    path: '/chain/alliance/justice',
    name: 'Justice',
    props: {
      formId: 52,
      label: '司法链服',
    },
    component: Justice,
  },
  {
    path: '/chain/alliance/ssebs',
    name: 'Ssebs',
    props: {
      formId: 53,
      label: '上证链服',
    },
    component: SSEBS,
  },
  {
    path: '/chain/alliance/iec',
    name: 'IEC',
    props: {
      formId: 54,
      label: '保交链服',
    },
    component: IEC,
  },
  {
    path: '/chain/alliance/wtc',
    name: 'WTC',
    props: {
      formId: 55,
      label: '梧桐链服',
    },
    component: WTC,
  },
  {
    path: '/chain/alliance/antsc',
    name: 'Antsc',
    props: {
      formId: 56,
      label: '蚂蚁溯源链服务',
    },
    component: Antsc,
  },
  {
    path: '/solution/digital',
    name: 'Digital',
    props: {
      formId: 57,
      label: '数字消费资产解决方案',
    },
    component: Digital,
  },
  {
    path: '/solution/farming',
    name: 'Farming',
    props: {
      formId: 58,
      label: '区块链+农业解决方案',
    },
    component: Farming,
  },
  {
    path: '/solution/landed',
    name: 'Landed',
    props: {
      formId: 59,
      label: '区块链+地产解决方案',
    },
    component: Landed,
  },
  {
    path: '/solution/human',
    name: 'Human',
    props: {
      formId: 60,
      label: '区块链+人资解决方案',
    },
    component: Human,
  },
  {
    path: '/solution/supplychain',
    name: 'SupplyChain',
    props: {
      formId: 61,
      label: '区块链+供应链解决方案',
    },
    component: SupplyChain,
  },
  {
    path: '/solution/financial',
    name: 'Financial',
    props: {
      formId: 62,
      label: '区块链+金融解决方案',
    },
    component: Financial,
  },
  {
    path: '/solution/internet',
    name: 'Internet',
    props: {
      formId: 63,
      label: '区块链+物联网解决方案',
    },
    component: Internet,
  },
  {
    path: '/chain',
    name: 'Chain',
    props: {
      label: '区块链服务',
    },
    component: Chain,
    children: [
      {
        path: '/chain/basic',
        name: 'Basic',
        props: {
          label: '基础服务',
        },
      },
      {
        path: '/chain/alliance',
        name: 'Alliance',
        props: {
          label: '联盟链服',
        },
      },
      {
        path: 'cloud',
        name: 'Cloud',
        props: {
          label: '云服务',
        },
        children: [
          {
            path: 'aliyun',
            name: 'Aliyun',
            props: {
              formId: 5,
              label: '阿里云',
            },
            component: Aliyun,
          },
          {
            path: 'huawei',
            name: 'Huawei',
            props: {
              formId: 7,
              label: '华为云',
            },
            component: Huawei,
          },
          {
            path: 'tencent',
            name: 'Tencent',
            props: {
              formId: 6,
              label: '腾讯云',
            },
            component: Tencent,
          },
        ],
      },
      {
        path: 'ca',
        name: 'CA',
        props: {
          label: 'CA服务',
        },
        children: [
          {
            path: 'api',
            name: 'API',
            props: {
              label: 'API服务',
            },
            children: [
              {
                path: 'rni-cert',
                name: 'RNICert',
                props: {
                  label: '实名身份数字证书服务API',
                },
                component: RNICert,
              },
              {
                path: 'rni-timestamp',
                name: 'RNITimestamp',
                props: {
                  label: '实名身份时间戳服务API',
                },
                component: RNITimestamp,
              },
              {
                path: 'static-id',
                name: 'StaticId',
                props: {
                  label: '静态活体认证API',
                },
                component: StaticId,
              },
              {
                path: 'inperson-cert',
                name: 'InPersonCert',
                props: {
                  label: '实人认证数字证书服务API',
                },
                component: InPersonCert,
              },
              {
                path: 'video-id',
                name: 'VideoId',
                props: {
                  label: '视频活体检测API',
                },
                component: VideoId,
              },
              {
                path: 'eb-info',
                name: 'EBInfo',
                props: {
                  label: '企业工商信息API',
                },
                component: EBInfo,
              },
              {
                path: 'eb-detail',
                name: 'EBDetail',
                props: {
                  label: '企业工商详细信息API',
                },
                component: EBDetail,
              },
              {
                path: 'rn-phone-veri',
                name: 'RNPhoneVeri',
                props: {
                  label: '手机号实名核验API',
                },
                component: RNPhoneVeri,
              },
            ],
          },
          {
            path: 'verification',
            name: 'Verification',
            props: {
              label: '数字认证服务',
            },
            children: [
              {
                path: 'cert-widget',
                name: 'CertWidget',
                props: {
                  label: '证书应用控件',
                },
                component: CertWidget,
              },
              {
                path: 'digi-cert',
                name: 'DigiCert',
                props: {
                  label: '数字证书',
                },
                component: DigiCert,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/appstore',
    name: 'AppStore',
    props: {
      label: '应用市场',
    },
    component: AppStore,
  },
  {
    path: '/audition',
    name: 'Audition',
    props: {
      label: '测评认证',
    },
    component: Audition,
  },
  {
    path: '/support',
    name: 'Support',
    props: {
      label: '服务与支持',
    },
    component: Support,
    children: [
      {
        path: 'self-assist',
        name: 'SelfAssist',
        props: {
          label: '自主服务中心',
        },
        component: SelfAssist,
      },
      {
        path: 'faq',
        name: 'FAQ',
        props: {
          label: '常见问题中心',
        },
        component: FAQ,
      },
      {
        path: 'discuss',
        name: 'Discuss',
        props: {
          label: '问答社区',
        },
        component: Discuss,
      },
      {
        path: 'bulletin',
        name: 'Bulletin',
        props: {
          label: '服务公告',
        },
        component: Bulletin,
      },
    ],
  },
  {
    path: '/about',
    name: 'About',
    props: {
      label: '关于我们',
    },
    component: About,
  },
  {
    path: '/console/login',
    name: 'Login',
    props: {
      label: '登录',
    },
    component: Account,
  },
  {
    path: '/console/display',
    name: 'ConsoleDisplay',
    props: {
      label: '展示',
    },
    component: ConsoleDisplay,
  },
  {
    path: '/console',
    name: 'Console',
    props: {
      invisible: true,
      label: '',
    },
    component: ConsoleBreadcrumb,
    meta: { NavMenuType: 'Account' },
    children: [
      {
        name: '管理控制台',
        path: 'management',
        props: {
          label: '管理控制台',
        },
        component: Management,
        meta: { NavMenuType: 'Account' },
      },
      {
        name: '账户信息',
        path: 'accountinfo',
        props: {
          label: '账户信息',
        },
        component: AccountInfo,
        meta: { NavMenuType: 'Account' },
      },
      {
        name: '企业认证',
        path: 'enterprisecertification',
        props: {
          label: '企业认证',
        },
        component: EnterpriseCertification,
        meta: { NavMenuType: 'Account' },
      },
      {
        name: '入驻申请',
        path: 'admission',
        props: {
          label: '入驻申请',
        },
        component: Admission,
        meta: { NavMenuType: 'Account' },
      },
      {
        name: '申请列表',
        path: 'admissionlist',
        props: {
          label: '申请列表',
        },
        component: AdmissionList,
        meta: { NavMenuType: 'Account' },
      },
    ],
  },
  {
    path: '/console',
    name: 'Console',
    props: {
      invisible: true,
      label: '',
    },
    component: Console,
    meta: { NavMenuType: 'Account' },
    children: [
      {
        path: 'baas/displayboard',
        props: {
          label: '联盟看板',
        },
        component: BaaSDisplayBoard,
        meta: { NavMenuType: 'BaaSDisplayBoard' },
      },
      {
        name: 'BaaS',
        path: 'baas/:baasId',
        props: {
          label: 'BaaS',
        },
        component: ConsoleBreadcrumb,
        meta: { NavMenuType: 'BaaS' },
        children: [
          {
            name: '联盟链管理',
            path: 'management',
            props: {
              formId: 65,
              label: '联盟链管理',
            },
            component: BaaSManagement,
            meta: { NavMenuType: 'BaaS' },
          },
          {
            name: '联盟链治理',
            path: 'governance/:chainId',
            props: {
              formId: 64,
              label: '联盟链治理',
            },
            component: BaaSGovernance,
            meta: { NavMenuType: 'BaaS' },
            children: [
              {
                name: '节点管理',
                path: 'node',
                props: { label: '节点管理' },
                component: BaaSGovernanceNode,
                meta: { NavMenuType: 'BaaS' },
              },
              {
                name: '配置管理',
                path: 'config',
                props: {
                  label: '配置管理',
                },
                component: BaaSGovernanceConfig,
                meta: { NavMenuType: 'BaaS' },
              },
              {
                name: '合约管理',
                path: 'contract',
                props: {
                  label: '合约管理',
                },
                component: BaaSGovernanceContract,
                meta: { NavMenuType: 'BaaS' },
              },
              {
                name: '账户管理',
                path: 'account',
                props: {
                  label: '账户管理',
                },
                component: BaaSGovernanceAccount,
                meta: { NavMenuType: 'BaaS' },
              },
              {
                name: '应用服务',
                path: 'application',
                props: {
                  label: '应用服务',
                },
                component: BaaSGovernanceApplication,
                meta: { NavMenuType: 'BaaS' },
              },
              {
                name: '跨链服务(BaaS)',
                path: 'crosschain',
                props: {
                  formId: 66,
                  label: '跨链服务(BaaS)',
                },
                component: BaaSGovernanceCrosschain,
                meta: { NavMenuType: 'BaaS' },
              },
              {
                name: '预言机服务(BaaS)',
                path: 'oracle',
                props: {
                  formId: 67,
                  label: '预言机服务(BaaS)',
                },
                component: BaaSGovernanceOracle,
                meta: { NavMenuType: 'BaaS' },
              },
              {
                path: '*',
                props: {
                  label: '治理',
                },
                component: BaaSGovernanceNode,
                meta: { NavMenuType: 'BaaS' },
              },
            ],
          },
          {
            name: '证书管理',
            path: 'certificate',
            props: {
              label: '证书管理',
            },
            component: BaaSCertificate,
            meta: { NavMenuType: 'BaaS' },
          },
          {
            name: '应用管理',
            path: 'application',
            props: {
              label: '应用管理',
            },
            component: BaaSApplication,
            meta: { NavMenuType: 'BaaS' },
          },
          {
            name: '联盟配置',
            path: 'config',
            props: {
              label: '联盟配置',
            },
            component: Management,
            meta: { NavMenuType: 'BaaS' },
          },
          {
            name: '联盟管理',
            path: 'union/management',
            props: {
              label: '联盟管理',
            },
            component: BaaSUnionManage,
            meta: { NavMenuType: 'BaaS' },
          },
          {
            path: '*',
            props: {
              label: '联盟链管理',
            },
            component: BaaSManagement,
            meta: { NavMenuType: 'BaaS' },
          },
        ],
      },
      {
        name: '蚂蚁溯源管理后台',
        path: 'sourcetrace',
        props: {
          label: '蚂蚁溯源管理后台',
        },
        component: ConsoleBreadcrumb,
        meta: { NavMenuType: 'SourceTrace' },
        children: [
          {
            name: '商户管理',
            path: 'merchant',
            props: {
              label: '商户管理',
            },
            component: SourceTraceMerchant,
            meta: { NavMenuType: 'SourceTrace' },
          },
          {
            name: '商品管理',
            path: 'commodity',
            props: {
              label: '商品管理',
            },
            component: SourceTraceCommodity,
            meta: { NavMenuType: 'SourceTrace' },
          },
          {
            name: '二维码管理',
            path: 'qrcode',
            props: {
              label: '二维码管理',
            },
            component: SourceTraceQrcode,
            meta: { NavMenuType: 'SourceTrace' },
          },
          {
            name: '批次管理',
            path: 'batch',
            props: {
              label: '批次管理',
            },
            component: SourceTraceBatch,
            meta: { NavMenuType: 'SourceTrace' },
          },
          {
            name: '配置管理',
            path: 'config',
            props: {
              label: '配置管理',
            },
            component: SourceTraceConfig,
            meta: { NavMenuType: 'SourceTrace' },
          },
          {
            name: '账户管理',
            path: 'account',
            props: {
              label: '账户管理',
            },
            component: SourceTraceAccount,
            meta: { NavMenuType: 'SourceTrace' },
          },
          {
            path: '*',
            props: {
              label: '总览',
            },
            component: Management,
            meta: { NavMenuType: 'SourceTrace' },
          },
        ],
      },
      {
        name: '隐私计算',
        path: 'privacy',
        props: {
          label: '隐私计算管理后台',
        },
        component: Console,
        meta: { NavMenuType: 'Privacy' },
        children: [
          {
            name: '我的数据',
            path: 'data',
            props: {
              label: '我的数据',
            },
            component: ConsoleBreadcrumb,
            meta: { NavMenuType: 'Privacy' },
            children: [
              {
                name: '数据管理',
                path: 'management',
                props: {
                  label: '数据管理',
                },
                component: PrivacyDataManagement,
                meta: { NavMenuType: 'Privacy' },
              },
              {
                name: '安全服务',
                path: 'security',
                props: {
                  label: '安全服务',
                },
                component: PrivacyDataSecurity,
                meta: { NavMenuType: 'Privacy' },
              },
              {
                name: '模型服务',
                path: 'model',
                props: {
                  label: '模型服务',
                },
                component: PrivacyDataModel,
                meta: { NavMenuType: 'Privacy' },
              },
              {
                name: '配置模型',
                path: 'configmodel',
                props: {
                  label: '配置模型',
                },
                component: PrivacyDataConfigmodel,
                meta: { NavMenuType: 'Privacy' },
              },
            ],
          },
        ],
      },
      {
        name: '隐私计算',
        path: 'privacy',
        props: {
          label: '隐私计算管理后台',
        },
        component: ConsoleBreadcrumb,
        meta: { NavMenuType: 'Privacy' },
        children: [
          {
            name: '概览',
            path: 'info',
            props: {
              label: '概览',
            },
            component: Management,
            meta: { NavMenuType: 'Privacy' },
          },
          {
            name: '我的项目',
            path: 'project',
            props: {
              label: '我的项目',
            },
            component: PrivacyProject,
            meta: { NavMenuType: 'Privacy' },
          },
          {
            name: '监控分析',
            path: 'monitor',
            props: {
              label: '监控分析',
            },
            component: PrivacyMonitor,
            meta: { NavMenuType: 'Privacy' },
          },
          {
            name: '概览',
            path: '*',
            props: {
              label: '概览',
            },
            component: Management,
            meta: { NavMenuType: 'Privacy' },
          },
        ],
      },
      {
        name: '数字身份',
        path: 'did',
        props: {
          label: '数字身份DID',
        },
        component: ConsoleBreadcrumb,
        meta: { NavMenuType: 'DID' },
        children: [
          {
            name: '概览',
            path: 'info',
            props: {
              label: '概览',
            },
            component: Management,
            meta: { NavMenuType: 'DID' },
          },
          {
            name: '用户管理',
            path: 'account',
            props: {
              label: '用户管理',
            },
            component: DIDAccount,
            meta: { NavMenuType: 'DID' },
          },
          {
            name: '配置',
            path: 'config',
            props: {
              label: '配置',
            },
            component: DIDConfig,
            meta: { NavMenuType: 'DID' },
          },
          {
            name: '浏览器',
            path: 'scan',
            props: {
              label: '浏览器',
            },
            component: Management,
            meta: { NavMenuType: 'DID' },
          },
          {
            name: '概览',
            path: '*',
            props: {
              label: '概览',
            },
            component: Management,
            meta: { NavMenuType: 'DID' },
          },
        ],
      },
      {
        path: '*',
        props: {
          label: '总览',
        },
        component: Management,
        meta: { NavMenuType: 'Account' },
      },
    ],
  },
  {
    path: '/form',
    name: 'Form',
    props: {
      invisible: true,
      label: '',
    },
    component: Form,
  },
  {
    path: '/thanks',
    name: 'Thanks',
    props: {
      label: '建设中',
    },
    component: Thanks,
  },
  {
    path: '/solution1',
    name: 'solution1',
    props: {
      label: 'solution1',
    },
    component: Solution1,
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.beforeEach(async (to, from, next) => {
  // window.localStorage.getItem('token') === null
  if (to.name !== 'Login' && (Cookies.get('passToken', { path: '/', domain: '.yuhu.tech' }) == null || Cookies.get('userName', { path: '/', domain: '.yuhu.tech' }) == null)) {
    if (to.path.search(/console*/) === 1 || to.path.search(/form*/) === 1) {
      next({ path: '/console/login', query: { redirect: to.path, ...to.query } });
    } else next();
  } else next();
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
