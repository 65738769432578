<template>
  <div>
    <div class="box-top">
      <el-button @click="add">添加商品</el-button>

      <el-dialog :visible.sync="dialogVisible" width="40%" title="添加商品">
        <div>
          <div class="dialog-input">
            <div class="dialog-text">*商品名称</div>
            <el-input
              v-model="addForm.Commodity"
              placeholder="商品名称"
              class="input"
            ></el-input>
          </div>
          <div class="dialog-input">
            <div class="dialog-text">*所属商户</div>
            <el-select
              v-model="addForm.selectMerchant"
              placeholder="所属商户"
              class="input"
            >
              <el-option
                v-for="item in merchants"
                :key="item.id"
                :label="item.merchantName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="dialog-input">
            <div class="dialog-text">*选择溯源环节模版</div>
            <el-select
              v-model="addForm.traceabilityLinkPlate"
              placeholder="不选模版"
              class="input"
            >
              <el-option
                v-for="item in traceabilityLinkPlates"
                :key="item.id"
                :label="item.plateName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="dialog-input">
            <div class="dialog-text">备注</div>
            <el-input
              type="textarea"
              v-model="addForm.desc"
              placeholder="备注"
              class="input"
            ></el-input>
          </div>
          <span slot="footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">
              确 定
            </el-button>
          </span>
        </div>
      </el-dialog>
    </div>

    <div>
      <el-table :data="tableData" border>
        <el-table-column prop="commodity" label="商品名称"> </el-table-column>
        <el-table-column prop="merchant" label="所属商户"> </el-table-column>
        <el-table-column prop="traceabilityLink" label="溯源环节">
          <template v-slot="scope">
            <span>{{ scope.row.traceabilityLinkPlate }}</span>
            <el-divider direction="vertical"></el-divider>
            <el-button
              @click="fixSourcetrace(scope.row)"
              type="text"
              size="small"
            >
              修改
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="appletPlate" label="小程序模版">
          <template v-slot="scope">
            <span>{{ scope.row.appletPlate }}</span>
            <el-divider direction="vertical"></el-divider>
            <el-button
              @click="appletPlate(scope.row)"
              type="text"
              size="small"
              min-width="30"
            >
              修改
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="180">
          <template v-slot="scope">
            <el-button @click="detail(scope.row)" type="text" size="small">
              详情
            </el-button>
            <el-button @click="dialogVisible = true" type="text" size="small">
              编辑
            </el-button>
            <el-button type="text" size="small"> 删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :visible.sync="dialogVisibleDetail"
      width="70%"
      title="基本信息"
      :center="false"
    >
      <div>
        <div class="box2">
          <div class="box-text">商品名称：{{ detailForm.row.commodity }}</div>
          <div class="box-text">商品ID：{{ detailForm.row.commodityId }}</div>
        </div>
        <div class="box2">
          <div class="box-text">
            所属品牌商：{{ detailForm.row.brandOwner }}
          </div>
          <div class="box-text">
            溯源环节模版：{{ detailForm.row.traceabilityLinkPlate }}
          </div>
        </div>
        <div class="box2">
          <div class="box-text">
            小程序模版：{{ detailForm.row.appletPlate }}
          </div>
          <div class="box-text">备注：{{ detailForm.row.operation }}</div>
        </div>
        <div style="height: 180px">
          <el-steps direction="vertical" :active="1" class="steps">
            <el-step
              v-for="item in detailForm.traceabilityLinks"
              :key="item.traceabilityLink"
              :title="'环节名称：' + item.traceabilityLink"
              description=""
              status="process"
            >
              <template slot="description">
                <span>
                  环节编号：{{ item.traceabilityLinkSn }} <br />
                  备注：{{ item.desc }}
                </span>
              </template>
            </el-step>
          </el-steps>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisibleApplet" width="60%">
      <div>
        <div class="dialog-applet">
          <img
            src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
            class="dialog-applet-img"
          />
          <img
            src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
            class="dialog-applet-img"
          />
          <img
            src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
            class="dialog-applet-img"
          />
        </div>
        <span slot="footer">
          <el-button @click="dialogVisibleApplet = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisibleApplet = false">
            确 定
          </el-button>
        </span>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogVisibleFixSourcetrace"
      width="40%"
      title="配置溯源环节"
    >
      <div>
        <div class="dialog-input">
          <div class="dialog-text">*溯源环节名称</div>
          <el-input
            v-model="fixSourcetraceForm.traceabilityLink"
            placeholder="请输入溯源环节名称"
            class="input"
          ></el-input>
        </div>
        <div class="dialog-input">
          <div class="dialog-text">*溯源环节编号</div>
          <el-input
            v-model="fixSourcetraceForm.traceabilityLinkSn"
            placeholder="溯源环节编号"
            class="input"
          ></el-input>
        </div>
        <div class="dialog-input">
          <div class="dialog-text">备注</div>
          <el-input
            type="textarea"
            v-model="fixSourcetraceForm.desc"
            placeholder="备注"
            class="input"
          ></el-input>
        </div>
        <div class="button-icon-plus">
          <el-button
            plain
            icon="el-icon-plus"
            style="width: 100%"
            @click="dialogVisibleFixSourcetrace = false"
          ></el-button>
        </div>
        <span slot="footer">
          <el-button @click="dialogVisibleFixSourcetrace = false"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="dialogVisibleFixSourcetrace = false"
          >
            确 定
          </el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          commodity: '口感型大番茄',
          commodityId: '0xb365C1825D46e280191EFcFD93Ca3ABEcB6cD601',
          merchant: '浙江绿迹农业科技有限公司',
          brandOwner: '浙江绿迹农业科技有限公司',
          traceabilityLinkPlate: '大番茄专用模版',
          appletPlate: '小程序模版-默认',
          operation: '',
        },
      ],
      dialogVisible: false,
      merchants: [
        {
          id: 'id1',
          merchantName: '浙江绿迹农业科技有限公司',
        },
        {
          id: 'id2',
          merchantName: '上海歆香蔬果专业合作社',
        },
      ],
      traceabilityLinkPlates: [
        {
          id: 'id1',
          plateName: '大番茄专用模版',
        },
        {
          id: 'id2',
          plateName: '生菜专用模版',
        },
      ],
      addForm: {
        Commodity: '',
        selectMerchant: '',
        traceabilityLinkPlate: '',
        desc: '',
      },
      dialogVisibleDetail: false,
      detailForm: {
        row: '',
        traceabilityLinks: [
          {
            traceabilityLink: '入库',
            traceabilityLinkSn: '853',
            desc: '第2019070916批次',
          },
          {
            traceabilityLink: '出库',
            traceabilityLinkSn: '1084',
            desc: '第2019070916批次',
          },
        ],
      },
      dialogVisibleApplet: false,
      fixSourcetraceForm: {
        name: '',
        traceabilityLink: '',
        traceabilityLinkSn: '',
        desc: '',
      },
      dialogVisibleFixSourcetrace: false,
    };
  },
  methods: {
    add() {
      this.dialogVisible = true;
    },
    detail(row) {
      console.log(row);
      this.detailForm.row = row;
      this.dialogVisibleDetail = true;
    },
    fixSourcetrace(row) {
      console.log(row);
      this.dialogVisibleFixSourcetrace = true;
    },
    appletPlate(row) {
      console.log(row);
      this.dialogVisibleApplet = true;
    },
  },
};
</script>

<style scoped>
.box-config {
  width: 100%;
}

.dialog-input {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-around;
  text-align: left;
}

.dialog-text {
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input {
  width: 100%;
}

.box2 {
  display: flex;
  margin: 16px 0;
  margin-left: 60px;
}

.box-text {
  min-width: 44%;
}

.steps {
  margin-left: 60px;
}

.dialog-applet {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 300px;
}

.dialog-applet-img {
  height: 100%;
  width: 30%;
}

.button-icon-plus {
  margin: 20px 0 20px 0;
  display: flex;
  justify-content: center;
}
</style>
